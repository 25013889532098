@use "../../../scss/mixins" as *;

.wrapper {
  background-color: $superlight;
  @include main-box-shadow;
  border-radius: 8px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    font-size: 24px;
    font-weight: $semi;
    color: $text-gray;
    text-align: center;
    margin-bottom: 8px;
    margin-top: 32px;
  }

  :global {
    .dropdown {
      margin-bottom: 24px;

      .divider {
        border-bottom: none;
        font-size: 14px;
        color: $text-gray;
        line-height: 24px;
        font-weight: $thin;
      }
    }
  }

  .searchWrapper {
    width: 376px;
    margin-bottom: 24px;
    border-radius: 8px;

    .search {
      width: 100%;

      input {
        border: 1px solid $light-border !important;
        border-radius: 8px !important;
        padding-left: 16px !important;
        padding-right: 50px !important;
        height: 48px;

        &:active,
        &:focus {
          border-color: $primary !important;
        }

        ~ i {
          cursor: pointer;
          pointer-events: all;
          padding: 0px 20px;
          right: 4px !important;
          left: unset !important;
          color: $superlight !important;
          background-color: $primary;
          opacity: 1 !important;
          border-radius: 8px !important;
          height: 40px !important;
          width: 40px !important;
          top: 4px !important;
        }
      }
    }
  }

  .usersTable {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 312px;
    min-height: 312px;
    overflow-y: auto;
    position: relative;

    &.noUserFound {
      min-height: 332px;
      max-height: 332px;
    }

    .newPageLoader {
      position: relative;
      min-height: 65px;
      width: 100%;
    }

    .searchNotFound {
      p {
        margin: 24px 0px 24px 0px;
      }

      svg {
        margin-right: 0px;
        width: 200px;
        height: 200px;
      }
    }

    .skeleton {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      column-gap: 24px;
      width: 100%;
      padding: 16px 24px;
      border-bottom: 0.5px solid $gray-placeholder;
      min-height: 62px;

      &:last-of-type {
        border-bottom: 0px;
      }
    }
  }
}
