@use "../../../../scss/variables" as *;

.row {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 24px;
  width: 100%;
  padding: 16px 24px;
  border-bottom: 0.5px solid $gray-placeholder;

  &:last-of-type {
    border-bottom: 0px;
  }

  .userName {
    display: flex;
    align-items: center;
    color: $primary-darken;
    font-size: 14px;
    font-weight: $semi;
  }

  .lastActivity {
    display: flex;
    align-items: center;
    color: $text-gray;
    font-size: 12px;
    font-weight: $normal;

    svg {
      margin-right: 8px;
    }
  }

  .dataCell {
    display: flex;
    align-items: center;
    color: $gray;
    font-weight: $semi;
    font-size: 12px;
  }

  .eyeButtonWrapper {
    margin-left: auto;

    .button {
      background-color: $superlight;
    }
  }
}
