.noResults {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  .noResultsText {
    font-size: 16px;
    color: #3d3d3d;
    font-weight: 400;
    line-height: 1.5em;
    margin: 0 0 70px;
  }
}
