@use "../../../../../scss/variables" as *;
@use "../../../../../scss/mixins" as mixins;

.wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
}

.input {
  margin-right: 0.5rem;
  width: 160px;
  padding: 5px;
}

.button {
  padding: 9px 7px;
  border: 1px solid $primary;
  background-color: $superlight;
  @include mixins.default-easing(background-color);

  svg {
    width: 18px;
    height: 14px;
    margin: 0;

    path {
      fill: $primary;
      @include mixins.default-easing(fill);
    }
  }

  &:hover,
  &:focus,
  &:active {
    background-color: $primary;
    box-shadow: none;

    svg {
      path {
        fill: $superlight;
      }
    }
  }

  &:focus {
    box-shadow: inset 0px 0px 2px $superlight;
  }
}
