@use "../../../scss/variables" as *;

.wrapper {
  height: 100%;
}

.empty {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 20px 0;

  .message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: $light-blue;
    color: $text-blue;
    border-radius: 8px;
    padding: 20px;

    &Button {
      display: inline-block;
      width: auto;
      justify-self: center;
      margin-top: 20px;
    }
  }
}

.modal {
  &Content {
    width: 100%;
    max-width: 440px;
  }

  &Description {
    margin-top: 1.5rem;
  }
}

.actions {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &Btn {
    .icon_ {
      &eye {
        path {
          fill: none;
          stroke: $primary;

          &:first-child {
            stroke-width: 0px;
          }
        }
      }

      &trash {
        path {
          fill: $errorBg;
        }
      }

      &edit {
        path {
          fill: $primary;
        }
      }
    }

    &:focus {
      .icon_trash {
        filter: none !important;

        path {
          fill: $errorBg;
        }
      }

      &:hover {
        .icon_trash {
          filter: none !important;

          path {
            fill: $superlight;
          }
        }
      }
    }

    &:hover,
    &:active {
      .icon_ {
        &eye {
          path {
            stroke: $superlight;
          }
        }

        &edit {
          path {
            fill: $superlight;
          }
        }
      }
    }
  }
}

.creationDetails {
  font-size: $xxs;
  line-height: 1.25em;
  color: $gray;
}

.details {
  &Question {
    font-size: $xs;
    color: $primary-darken;
    margin: 0 0 5px;
  }

  &Description {
    font-size: $xs;
    color: $text-gray;
  }
}

.table {
  &RowItem {
    align-items: flex-start;
  }
}
