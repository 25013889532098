@import "./variables";

@mixin main-box-shadow(
  $first: rgba(224, 224, 224, 0.45),
  $second: rgba(204, 218, 238, 0.2)
) {
  box-shadow: 0px 2px 4px $first, 0px 10px 14px $second;
}

@mixin shadow(
  $color: rgba(0, 0, 0, 0.05),
  $xAxis: 4px,
  $yAxis: 4px,
  $blur: 20px,
  $spread: 0px
) {
  box-shadow: $xAxis $yAxis $blur $spread $color;
}

$defaultShadow: shadow();

@mixin multipleShadow($boxShadows...) {
  box-shadow: $boxShadows;
}

@mixin vertical-scrollbar($width: 7px, $height: 7px) {
  ::-webkit-scrollbar {
    width: $width !important;
    height: $height !important;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
}

@mixin vertical-scrollbar-custom-element(
  $width: 7px,
  $height: 7px,
  $thumb-color: rgba(0, 0, 0, 0.25)
) {
  &::-webkit-scrollbar {
    width: $width !important;
    height: $height !important;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $thumb-color !important;
  }
}

@mixin button-focus-shadow($color: $primary) {
  box-shadow: 0px 0px 0px 4px rgba($color, 0.5),
    inset 0px 0px 2px darken($color, 5%);
}

@mixin default-easing($properties...) {
  transition: $properties 0.2s ease;
}

@mixin fast-easing($properties...) {
  transition: $properties 0.125s ease;
}

@mixin combined-transitions($transitions...) {
  transition: $transitions;
}

@mixin col-size($times: 1) {
  flex-basis: calc((100% / 12) * #{$times});
}

@mixin table-sizes {
  &.col-1 {
    @include col-size(1);
  }

  &.col-2 {
    @include col-size(2);
  }

  &.col-3 {
    @include col-size(3);
  }

  &.col-4 {
    @include col-size(4);
  }

  &.col-5 {
    @include col-size(5);
  }

  &.col-6 {
    @include col-size(6);
  }

  &.col-7 {
    @include col-size(7);
  }

  &.col-8 {
    @include col-size(8);
  }

  &.col-9 {
    @include col-size(9);
  }

  &.col-10 {
    @include col-size(10);
  }

  &.col-11 {
    @include col-size(11);
  }

  &.col-12 {
    flex-basis: 100%;
  }
}

@mixin commonTextSizes {
  font-family: "Poppins", sans-serif;
  font-size: 14px;

  h1 {
    font-size: 20px;
  }

  h2 {
    font-size: 18px;
  }

  h3 {
    font-size: 16px;
  }
}
