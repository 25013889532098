@use "../../../../../scss/variables" as *;

.wrapper {
  background-color: $primary-bg-light;
  padding: 16px;
  padding-left: 20px;
  min-width: 200px;
  max-width: 300px;

  &Error {
    background-color: lighten($error, 55%);
  }
}

.targetHandle {
  width: 24px;
  height: 24px;
  left: -14px;
  border: 1px solid $gray;
  border-radius: 4px;
  background-color: $superlight;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: $light;
  }

  &::after {
    content: url("../../../../../../src/assets/icons/dot-list.svg");
    width: 14px;
    height: 14px;
    border: none;
    border-radius: 0;
    background-color: transparent;
  }
}

.text {
  font-size: $xs;
  color: $gray;
  word-wrap: break-word;
}
