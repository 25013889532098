@use "../../../scss/variables" as *;

.title {
  font-size: $xs;
  line-height: $s;
  font-weight: $normal;
  color: $gray;
  margin-bottom: 0;

  .question {
    font-weight: $semi;
    color: $primary-shadowed;
  }
}
