@use "../../../../scss/mixins" as mixins;
@use "../../../../scss/variables" as *;

.wrapper {
  height: 100%;
  padding: 4px;
  position: relative;

  &Header {
    height: unset;
    padding-left: 24px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 999;
  }
}

.iconButton {
  width: 32px;
  height: 32px;
  background-color: $gray;
  padding: 6px;

  svg {
    width: 20px;
    height: 20px;
    size: unset;
    margin: 0;
  }

  &Remove {
    svg {
      path {
        stroke: $superlight;
      }
    }

    &:hover,
    &:focus,
    &:active {
      background-color: rgba($error, 0.15);

      svg {
        path {
          stroke: $error;
        }
      }
    }

    &:focus {
      @include mixins.button-focus-shadow(rgba($error, 0.15));
    }
  }

  &Edit {
    &:hover,
    &:active {
      background-color: $text-gray;
    }

    &:focus {
      @include mixins.button-focus-shadow($text-gray);
    }
  }
}

.headerButtons {
  padding: 8px;
  background-color: $superlight;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.5rem;
  @include mixins.main-box-shadow;

  .headerButton {
    margin-right: 0.5rem;

    &Text {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
}

.save {
  margin-left: 1.5rem;
}

.flow {
  border-radius: 8px;
}
