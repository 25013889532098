.actions {
  display: flex;
  justify-content: flex-end;

  &AlignLeft {
    left: 0;
    right: unset;
  }
}

.action {
  color: #707070;
  font-size: 12px !important;
  text-decoration: none !important;

  &:hover {
    color: #00b9ab;
  }
}

svg {
  size: 14px;
  margin-right: 4px;
}

.list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  list-style: none;
  padding: 0;
  margin: 0;

  &Item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    &:nth-of-type(2) {
      margin-left: 12px;
    }
  }
}
