@use "../../scss/mixins" as mixins;
@use "../../scss/variables" as *;

.Editor {
  height: 100%;
  padding: 0 0 0 32px;

  &Content {
    padding: 10px 0 20px;
    @include mixins.vertical-scrollbar(10px);
  }

  :global {
    .tox {
      &.tox-tinymce {
        border: none !important;

        &--toolbar-sticky-on {
          .tox-editor-container {
            padding-top: 0 !important;
          }

          .tox-editor-header {
            position: sticky !important;
          }
        }

        &.tox-tinymce-inline {
          .tox-editor-header {
            background-color: transparent;
            border: none;

            * {
              background-color: transparent;
            }
          }
        }
      }

      .tox-toolbar {
        background-image: unset !important;

        &__group {
          padding-right: 12px;
          padding-left: 0px;
        }
      }

      .tox-split-button {
        margin: 2px 1px 2px 0px;
      }

      .tox-tbtn {
        width: 24px;
        height: 26px;
        margin: 2px 1px 2px 0;

        &--select {
          width: auto;
          margin: 2px 1px 2px 0;
        }

        .tox-icon {
          svg:not(#phone) {
            fill: $gray;
          }
        }

        &__select-label {
          color: $gray;
        }
      }

      &-editor-header {
        box-shadow: none !important;
      }
    }

    .mce-content-body {
      min-height: 200px;
      outline: none;
    }
  }
}
