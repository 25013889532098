:global(.ui).errorMessage {
  position: absolute;
  margin: auto !important;
  width: 400px;
  height: 80px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 200;
}

.errorDimmer {
  background: #000;
  opacity: 0.5;
  position: fixed; /* important to use fixed, not absolute */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}
