.RTEWrapper {
  :global {
    .tox {
      .tox-editor-header,
      .tox-toolbar-overlord,
      .tox-toolbar {
        background-color: transparent !important;
      }
    }
  }
}
