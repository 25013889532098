@use "../../../../scss/variables" as *;

.buttonsWrapper {
  background-color: $primary;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 12px 118px 12px;
  height: 64px;
  z-index: 2;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;

  a {
    margin-right: 16px;
  }

  .saveButton,
  .cancelButton {
    max-width: fit-content;
    height: 40px;
    width: 100%;
    border-radius: 8px;
  }

  .cancelButton {
    margin-left: 10px;
    border: 2px solid transparent !important;

    &.discard {
      margin: 0;
    }

    &:hover,
    &:focus {
      background-color: $lighter !important;
      color: $primary;
    }
  }

  .saveButton {
    margin-left: 10px;
    border: 2px solid transparent !important;
    box-shadow: none !important;
    background-color: $light;
    color: $primary;
    width: max-content;

    &.reversed {
      order: 1;
    }

    &:hover,
    &:focus {
      border-color: $lighter !important;
      background-color: $primary;
      color: $light;
    }
  }
}
