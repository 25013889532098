@import "../../../scss/variables";
@import "../../../scss/mixins";
@import "../../../scss/keyframes";

$menuIconMinWidth: 24px;
$menuIconMinHeight: 22px;
$menuIconMaxWidth: 24px;
$menuIconMaxHeight: 22px;

.sidebarMenu {
  overflow: hidden;
  white-space: nowrap;

  &:global(.ui.vertical.menu) {
    margin: 0;
    padding: 0;
    background-color: transparent;
    width: 100%;
    box-shadow: none;
    border: none;
    overflow-y: auto;
    margin-bottom: 30px;
    padding-bottom: 5px;
    @include vertical-scrollbar-custom-element(
      7px,
      7px,
      rgba(255, 255, 255, 0.4)
    );

    & :global(.item) {
      color: $gray;
      font-size: 14px;
      padding: 17px 0 17px 24px;

      &:hover {
        color: $primary;

        .dropDownMenu,
        .iconHoverBackground {
          display: block;
        }
      }

      & :global(.header) {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        margin: 0;
        font-weight: 400 !important;

        :global(.angle.up.icon),
        :global(.angle.down.icon) {
          margin-left: auto;
          margin-right: 20px;
          font-size: 1.5em;
          font-weight: bold;
          line-height: 0.9em;
        }

        svg {
          min-width: $menuIconMinWidth;
          min-height: $menuIconMinHeight;
          max-width: $menuIconMaxWidth;
          max-height: $menuIconMaxHeight;
          vertical-align: middle;
          margin-right: 24px;
          z-index: 1;
        }
      }

      &.sub_active {
        background-color: lighten($dark-blue, 1%);

        // remove whole block when submenu will be hidden by default
        &_active {
          color: $primary !important;
          :global(.header) {
            svg path {
              fill: $primary;
            }
          }
        }

        &_closed {
          padding-bottom: 17px;
        }

        // uncomment when submenu will be hidden by default
        // color: $primary !important;

        // uncomment when ∂submenu will be hidden by default
        // .header {
        // svg path {
        //   fill: $primary;
        // }
        // }

        & :global(.item).submenu {
          &:first-of-type {
            margin-top: 17px;
          }
        }
      }

      &.submenu {
        font-weight: 400;
        margin-left: 24px;
        padding: 10px 0 10px 24px;
      }

      &:global(.active) {
        color: $primary !important;
        position: relative;

        :global(.header) {
          svg path {
            fill: $primary;
          }
        }

        &::after {
          content: "";
          position: absolute;
          right: 0;
          top: 0;
          width: 6px;
          height: 100%;
          max-height: 56px;
          border-radius: 3px 0 0 3px;
          background-color: $primary;
        }
      }
    }
  }
}

.fadeIn {
  animation: fadeIn $menuAnimationTime;
  opacity: 1;
}

.fadeOut {
  animation: fadeOut $menuAnimationTime;
  opacity: 0;
}

.dropDownMenu {
  display: none;
  animation: fadeIn 0.3s;
  margin-left: 45px !important;
  margin-top: -40px !important;
  background-color: #000521;
  border-radius: 10px;
  min-width: 200px;
  position: fixed;
  z-index: 1000;
  border: 1px solid #e2e2e2;
  box-shadow: 0px 1px 4px 2px rgba(0, 0, 0, 0.2);

  a {
    &::after {
      border-radius: 3px 9px 9px 3px !important;
    }

    &:hover {
      color: $gray !important;
      border-radius: 9px !important;
      background-color: #24263f !important;
    }
  }
}

.sub_active_expanded {
  padding-bottom: 0px !important;
}

.iconHoverBackground {
  display: none;
  position: fixed;
  width: $menuIconMinWidth + 22px;
  height: $menuIconMinHeight * 2;
  margin: -10px;
  border-radius: 9px;
  background-color: #24263f;
  z-index: 0;
}
