@import "../../scss/mixins";
@import "../../scss/variables";

.chat {
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: 100%;
  border-radius: 8px;
  @include main-box-shadow;
  overflow-y: auto;
  overflow-x: auto;
  position: relative;
  padding-right: 0;

  &Wrapper {
    margin-right: 46px;
    width: calc(100% - 46px);
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  &TitleBar {
    font-size: 16px;
    padding: 16px 24px;
    color: $dark-blue;
    margin: 0;

    &Search {
      padding-bottom: 10px;
    }
  }

  &ExpandButton {
    background-color: $superlight;
    @include combined-transitions(
      background-color $default-easing-timing,
      box-shadow $fast-easing-timing
    );
    transform: rotate(-180deg);
    margin-left: auto;
    margin-right: 12px;

    &Icon {
      path {
        transition: stroke $default-easing-timing;
        stroke: $primary;
      }
    }

    &:hover,
    &:focus {
      background-color: $primary;
      outline: none;

      .chatExpandButtonIcon {
        path {
          stroke: $superlight;
        }
      }
    }

    &Opened {
      background-color: $primary;
      transform: rotate(0);

      .chatExpandButtonIcon {
        path {
          stroke: $superlight;
        }
      }

      &:hover {
        background-color: $superlight;

        .chatExpandButtonIcon {
          path {
            stroke: $primary;
          }
        }
      }
    }
  }

  :global(.document-header) {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    min-height: 37px;
    max-height: 110px;
    left: 0;
    right: 0;
    background: unset;
    margin: unset !important;
    box-shadow: unset;
    padding: unset !important;
    border-radius: 8px;
  }

  .documentHeader {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    min-height: 37px;
    max-height: 110px;
    left: 0;
    right: 0;
    background: unset;
    margin: unset;
    box-shadow: unset;
    padding: unset;
    border-radius: 8px;
  }

  img {
    max-width: 100%;
    height: auto;
  }
  p > img:hover {
    cursor: zoom-in;
  }

  &__noResultsMessage {
    width: calc(100% - 4em);
    margin: 0 auto;
    text-align: center;
    padding: 2em;
    border-radius: 0.5em;
    color: #b87700;
    background-color: lighten(#b87700, 54%);
    backdrop-filter: blur(8px);
  }
}

.searchBarContainer {
  width: 100%;
  background: #fff;
  z-index: 2;
}

.searchAnswersContainer {
  margin-top: 24px;
  height: 100%;
  overflow-y: auto;
}

.searchInput {
  display: block !important;
  width: 70%;
  margin: 0 auto;

  & > div:global(.ui.input) {
    & > input {
      width: 100%;
      font-size: 16px;
      margin: 0 auto !important;
      padding: 8px 2.67143em 8px 0 !important;
      border: unset !important;
      border-bottom: 1px solid #cfd0db !important;
      border-radius: unset !important;

      &::placeholder {
        color: #8d93ab !important;
      }
    }
    & > span {
      position: absolute;
      right: 0;
      height: 100%;

      & > i {
        font-size: 1.4em;
        color: $primary;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

.message {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: #383838;
  display: inline-block;
}

.answerDivider {
  align-self: center;
  width: 98%;
}

.answerWrapper {
  display: block;
  background-color: #f2f6f7;
  border-radius: 0 14px 14px 14px;
  margin: 20px 30px;
  padding: 25px;
  overflow-anchor: none;
}

.messageSection {
  line-height: 17px;
  border-left: 3px solid green;
  border-color: #20bda3;
  border-radius: 2px;
  padding-left: 16px;
  margin-bottom: 10px;
}

.tooLongMessageResponse {
  position: relative;
  overflow-y: hidden;
  max-height: 76px;
}

.messageAllowsExpand {
  cursor: pointer;
}

.showMoreBtn {
  border: none;
  background: none;
  color: #00b9ab;
  font-size: 12px;
  cursor: pointer;
}

.dataTipHover {
  background-color: #20bda3;
  color: white;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
}

:global(.group) {
  margin-left: 0 !important;

  &:first-child {
    > div,
    > img {
      align-items: center;
      margin-left: unset !important;
    }
  }
}
