.MessageLoader {
  width: 60px;
  text-align: center;
  & > div {
    width: 10px;
    height: 10px;
    background-color: #00b9ab;
    margin: 0 5px;

    border-radius: 100%;
    display: inline-block;
    animation: BTWBounce 1.4s infinite ease-in-out both;
  }
  .b1 {
    animation-delay: -0.32s;
  }
  .b2 {
    animation-delay: -0.32s;
  }
  @keyframes BTWBounce {
    0%,
    80%,
    100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
}
