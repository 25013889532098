div.DocumentSelectionDropdown:global(.ui.fluid.selection.dropdown) {
  border: none;
  margin-top: 10px;
  border-radius: 6px;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 0 !important;

  div:global(.visible.menu.transition) {
    border: none;
    margin-bottom: 10px;
  }
  i {
    color: #8d93ab;
    &:global(.chevron.down.icon) {
      position: absolute;
      right: 10px;
    }
  }

  div:global(.text) {
    min-height: 36px;
    padding: 0.67857143em 1em;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: -0.67857143em;
  }

  div:global(.visible.menu) {
    position: relative;
    top: unset;
    width: 100%;
    min-width: 100%;
    margin-bottom: 0;
    box-shadow: none !important;
  }
}
