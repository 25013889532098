.Full {
  position: fixed !important;
  margin: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2001 !important;
  opacity: unset !important;
  width: 100%;
  max-width: 90% !important;
  max-height: 90%;

  &Image {
    max-width: 100%;
  }
}

.ImageDimmer {
  background: #000;
  opacity: 0.5;
  position: fixed; /* important to use fixed, not absolute */
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  z-index: 2000 !important;
}
