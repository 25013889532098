.button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  /* text-decoration: underline; */
  display: inline;
  margin: 0;
  padding: 0;
  font-size: 12px !important;
  font-weight: 400;
  line-height: 14px;
}

.button:hover,
.button:focus {
  text-decoration: none;
}
