@use "../../../../scss/variables" as *;

.controls {
  display: flex;
  gap: 10px;
  border: none;
  box-shadow: none;
}

.plusIcon {
  path {
    stroke: $text-gray;
  }
}
