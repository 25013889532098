.counter {
  white-space: nowrap;
  background: rgba(141, 147, 170, 0.1);
  border-radius: 4px;
  padding: 4px 8px;
}

.button {
  background: none;
  border: none;
  margin-top: 3px;

  svg {
    width: 14px;
  }
}

.iconUp {
  transform: rotate(180deg);
}
