@mixin links {
  a {
    color: #20bda3;
    border-bottom: 1px solid transparent;
    &:hover {
      border-bottom: 1px solid #20bda3;
      cursor: pointer;
    }
  }
}

@mixin smallerFonts {
  p,
  a {
    font-size: 12px;
  }
  .ui.checkbox {
    label {
      font-size: 12px;
    }
  }
}

.logo {
  width: 141px;
  margin: 50px 0 70px 0;
  @media only screen and (max-width: 991px) {
    margin: 10px auto 70px auto;
  }
}

.loginFormGrid {
  width: 100%;
  @media only screen and (max-width: 991px) {
    &:global(.ui.middle.aligned.grid) {
      margin: 0;
    }
  }
  .loginForm {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .formInput {
    position: relative;
    &.hasError,
    &.hasFormError {
      :global(.ui.fluid.input) > input {
        color: #ea4336 !important;
        border-bottom: 1px solid #ea4336;
      }
    }
    div:global(.ui.fluid.input) > input {
      padding: 10px 0;
      border-top: none;
      border-right: none;
      border-left: none;
      border-radius: 0;
      margin-bottom: 15px;
      font-size: 16px;
      border-bottom: 1px solid #cfd0db;
      &:active,
      &:focus {
        border-bottom: 1px solid #20bda3;
      }
    }
  }
  .formLoader {
    max-width: 530px;
    width: 100%;
    margin-right: 80px;
    @media only screen and (max-width: 991px) {
      max-width: 100%;
      margin-right: 0;
      padding: 36px 25px;
    }
  }
  .formButton > button {
    padding: 20px 0;
    margin-top: 30px;
    margin-bottom: 40px;
    font-weight: 800;
    font-size: 16px;
    background-color: #20bda3;
    color: #fff;
    &:hover,
    &:focus {
      background-color: #20bda3;
      color: #fff;
    }
    &:active {
      background-color: #25af98;
      color: #fff;
    }
  }

  .termsWrapper {
    margin-top: 32px;
    .byLogging {
      font-weight: 400;
      font-size: 12px;
    }

    .byLoggingLink {
      font-weight: 400;
      font-size: 12px;
      color: #25af98;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.loginFormWrapper {
  max-width: 530px;
  background-color: #ffffff;
  box-shadow: 20px 22px 30px 0 rgba(67, 49, 117, 0.08);
  width: 100%;
  margin-right: 80px;
  padding: 60px 50px;
  position: relative;
  @media only screen and (max-width: 991px) {
    max-width: 100%;
    margin-right: 0;
    padding: 36px 25px;
  }
}

.loginHeader {
  color: #020525;
  font-size: 24px;
  font-weight: 800;
  line-height: 29px;
  margin: 0 0 40px 0;
}

.formError {
  color: #ea4336;
  font-size: 14px;
  font-weight: 700;
  position: absolute;
  margin-top: -20px;
}

.labelError {
  @extend .formError;
  z-index: 1;
  right: 0;
  margin-top: 10px;
}

.optionsWrapper {
  @include links;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 14px;
  .optionsWrapperCheckbox:global(.field) {
    margin: 0;
    :global(div.ui.checkbox) {
      &:global(.checked) {
        label {
          &:before {
            top: -1px;
            background-color: #20bda3;
          }
        }
      }
      label {
        color: #8d93ab;
        &:before {
          top: -1px;
          border: none;
          background-color: #fff;
          border: 1px solid #20bda3;
        }
        &:after {
          color: #fff !important;
          font-weight: 100;
        }
      }
    }
  }
  @media only screen and (max-width: 991px) {
    @include smallerFonts;
  }
}

.signInWrapper {
  @include links;
  margin-top: 50px;
  font-weight: 600;
  font-size: 14px;
  color: #8d93ab;

  button {
    margin-left: 5px;
    color: #20bda3;
    border: none;
    outline: none;
    border-bottom: 1px solid transparent;
    background-color: transparent;

    &:hover {
      border-bottom: 1px solid #20bda3;
      cursor: pointer;
    }
  }

  @media only screen and (max-width: 991px) {
    @include smallerFonts;
    margin-top: 20px;
    text-align: center;
  }
}
