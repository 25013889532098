@use "../../../../scss//mixins" as *;

@keyframes expand {
  from {
    max-width: 0px;
    opacity: 0;
  }
  to {
    max-width: 247px;
    opacity: 1;
  }
}

@keyframes reduce {
  from {
    max-width: 247px;
    opacity: 1;
  }
  to {
    max-width: 0px;
    opacity: 0;
  }
}

.table {
  border-right: 0px;
  position: absolute;
  z-index: 1;

  &.opened {
    display: flex;
    flex-direction: column;
    grid-column: 1 / 2;
    max-height: calc(100vh - 212px);
    height: 100%;
    padding: 0px 16px 40px 28px;
    animation: expand 0.2s linear;
    max-width: 247px;
    opacity: 1;

    > ul {
      word-break: break-word;
    }
  }

  &.closed {
    animation: reduce 0.2s linear;
    max-width: 0px;
    max-height: calc(100vh - 336px);
    height: 100%;
    opacity: 0;

    .contentsHeader {
      opacity: 0;
    }

    ul {
      opacity: 0;
    }
  }
}

.contentsHeader {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  .iconWithTitle {
    display: flex;
    .contentsIcon {
      stroke: $primary;
      margin-right: 8px;
    }

    h2 {
      font-size: 16px;
      font-weight: 400;
      padding: 0;
      color: $dark-blue;
      text-align: left;
      margin: 0;
      white-space: nowrap;
    }
  }
}

.closeButton {
  background-color: rgba(141, 147, 171, 0.3);
  height: fit-content;
  border-radius: 50%;
  margin-left: 16px;
  width: 24px;
  height: 24px;
  padding: 0px;
  @include default-easing(background-color);

  .icon {
    width: 14px;
    height: 14px;
  }

  &:hover {
    background-color: darken($superlight, 5%);
  }

  &:focus {
    @include button-focus-shadow(darken($superlight, 5%));
  }
}

.contentsButton {
  background-color: $superlight;
  border: 1px solid rgba(141, 147, 171, 0.3);
  height: fit-content;
  margin-top: 8px;
  @include default-easing(background-color);

  &:hover {
    background-color: darken($superlight, 5%);
  }

  &:focus {
    @include button-focus-shadow(darken($superlight, 5%));
  }
}
