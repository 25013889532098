@import "../../../../scss/variables";

.modal {
  border-radius: 8px !important;

  .wrapper {
    padding: 16px;

    .errorMessage {
      margin: 20px;
      width: unset;
    }

    .heading {
      color: $text-gray;
      font-size: 14px;
      font-weight: $normal;
      margin-bottom: 8px;

      &.second {
        margin: 24px 0px 16px 0px;
      }
    }

    .notifyRadios {
      display: grid;
      grid-template-columns: 1fr;
      row-gap: 16px;

      .radio {
        input:checked ~ label {
          &::after {
            background-color: $primary;
          }
          &::before {
            border-color: $primary;
          }
        }

        input ~ label {
          &::after {
            background-color: $primary;
            width: 20px;
            height: 20px;
          }
          &::before {
            border-color: $primary;
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    .buttons {
      margin-top: 24px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 10px;

      .publishButton,
      .cancelButton {
        height: 40px;
        width: 100%;
        border-radius: 8px;
      }

      .cancelButton {
        background-color: $light;
        border: 1px solid $primary;
        color: $primary;

        &:hover,
        &:focus {
          background-color: $primary;
          color: $light;
        }
      }

      .publishButton {
        border: 2px solid transparent;
        box-shadow: none;
        background-color: $primary;
        color: $light;

        &:hover,
        &:focus {
          background-color: darken($primary, 1.5%);
        }

        &.isSubmitting {
          color: transparent;
          position: relative;
          user-select: none;
        }
      }
    }
  }
}
