@import "../../../scss/variables";

.breadcrumbs {
  color: $text-gray;
  font-size: 14px;
  margin-left: 4px !important;
  font-weight: 400;

  a {
    color: $text-gray;
  }

  &First {
    color: #9c9c9c;
  }

  &Current {
    font-weight: 600;
  }

  &Separator {
    padding: 0 5px 0 5px;
  }
}
