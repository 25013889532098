@use "../../../scss/variables" as *;
@use "../../../scss/mixins" as Mixins;

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 4px 4px 0;
  border: 1px solid $line-gray;
  @include Mixins.fast-easing(border-color);

  &Focused {
    border-color: $primary;
  }
}

.autocomplete {
  width: 100%;
  margin-right: 0.25rem;

  :global(.ui.input) > :global(input) {
    border: none !important;
    padding: 11px 1rem 11px 1.5rem !important;
    border-radius: 0.5rem 0 0 0.5rem !important;
    box-shadow: none !important;
  }
}

.button {
  display: flex;
  box-shadow: none;
  padding: 11px 1rem;
  font-size: $xs;
  font-weight: $thin;
  border-radius: 0.5rem;

  &Icon {
    &:global(.icon) {
      font-size: $xs;
      font-weight: $thin;
    }
  }
}

.resetButton {
  display: flex;
  align-items: center;
  background-color: transparent;
  padding: 11px 5px;
  font-size: $xxs;
  font-weight: $thin;
  color: $gray;
  margin-right: 1rem;

  &Icon {
    &:global(.icon) {
      font-size: $xxs;
      font-weight: $thin;
      color: $gray;
    }
  }

  &:hover,
  &:focus,
  &:active {
    box-shadow: none;
    background-color: transparent;
    color: $error;

    .resetButtonIcon {
      &:global(.icon) {
        color: $error;
      }
    }
  }

  &:focus {
    text-decoration: underline;
  }
}

.suggestions {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.suggestion {
  display: block;

  &Button {
    display: block;
    margin: 0;
    padding: 0.25rem;
    background-color: rgba($primary, 0.15);
    color: $primary;
    font-size: $xxs;
    line-height: 1.5em;
    border: none;
    border-radius: 0.25rem;
    outline: none;
    cursor: pointer;
    @include Mixins.fast-easing(background-color, box-shadow);

    &:hover,
    &:focus {
      background-color: rgba($primary, 0.2);
    }

    &:focus {
      @include Mixins.button-focus-shadow;
    }
  }
}
