@import "../../scss/mixins";

.related {
  &-enter {
    opacity: 0;

    &-active {
      opacity: 1;
      @include default-easing(opacity);
    }
  }

  &-exit {
    opacity: 1;

    &-active {
      opacity: 0;
      @include default-easing(opacity);
    }
  }
}
