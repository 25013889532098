@use "../../../../../scss/variables" as *;

.button {
  background-color: $superlight;
  padding: 3px;
  border: none;
  color: $primary;

  &:hover,
  &:focus,
  &:active {
    color: $primary-shadowed;
    box-shadow: none;
    background-color: $superlight;
  }
}
