@import "../../../scss/mixins";
@import "../../../scss/variables";

.wrapper {
  width: 100%;
  margin-top: 2rem;
}

.inputContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  &Input {
    width: 100%;
    flex-basis: calc(100% - 50px);
    margin-right: 0.5rem;
  }

  &Btn {
    flex-basis: 50px;
    width: 50px;
    height: 50px;
  }
}

.synonyms {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 12px 0 0;
}

.synonym {
  padding: 0;
  border-radius: 8px;
  border: 1px solid rgba($gray, 0.5);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  background-color: $superlight;

  &:not(:first-of-type) {
    margin-left: 14px;
  }

  &Text {
    font-size: $xs;
    font-weight: $thin;
    color: $text-gray;
    margin: 0;
    padding: 8px;
  }

  &Delete {
    width: 30px;
    height: 30px;
    background-color: transparent;
    border: 1px solid transparent;
    outline: none;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    display: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    @include fast-easing(border-color, background-color);

    :global svg {
      width: 14px;
      height: 14px;
      transform: rotate(45deg);
      margin: 0;

      path {
        @include fast-easing(stroke);
        stroke: $gray;
      }
    }

    &:hover,
    &:active {
      background-color: rgba($error, 0.2);
      border-color: $error;

      :global svg {
        path {
          stroke: $error;
        }
      }
    }

    &:focus {
      border-color: $error;
    }
  }

  &:hover {
    border-color: rgba($primary, 0.2);
    background-color: rgba($primary, 0.2);

    .synonymDelete {
      display: flex;
    }
  }
}
