@import "../../scss/mixins";
@import "../../scss/variables";

.changesPageWrapper {
  ::-webkit-scrollbar {
    width: 7px !important;
    height: 7px !important;
  }

  background-color: #ffffff !important;
  display: block;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  @include main-box-shadow;
  border-radius: 8px;
  padding-top: 16px;

  .ui.search.dropdown > .text {
    word-break: break-all;
  }
}

.changesTableWrapper {
  background-color: #ffffff !important;
  display: block;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  overflow-y: hidden;
}

.changesScroll {
  width: 100%;
  overflow-y: auto;
  > .changesScrollElementsList {
    overflow-x: hidden;
    min-height: 100%;
  }
}

.changesData {
  max-width: 120px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  p > img {
    width: 100%;
    height: auto;
  }

  p > img:hover {
    cursor: zoom-in;
  }
}

.tableText {
  color: #000000;
  font-family: Helvetica;
  font-size: 12px !important;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  padding-right: 40px;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  padding-bottom: 0px;
}

.ResTable {
  display: flex;
  flex-wrap: wrap;
  padding-left: 2rem;
  padding-right: 2rem;

  &.headBkg {
    background-color: $superlight;
  }
}

.ResTable-cell {
  @extend .tableText;
  box-sizing: border-box;
  flex-grow: 1;
  padding: 22px 0;
  overflow: visible;
  list-style: none;
  width: 20%;
  align-items: center;
  display: flex;

  &:last-child {
    padding-right: 0px;
  }

  &.icons {
    .ui.fluid.item.dropdown {
      max-width: 50px;
      margin-left: auto;
      margin-right: 20px;
    }
  }
}

.greyText {
  @extend .tableText;
  color: #9b9b9b;
  padding-left: 10px;
}

.nrBox {
  background-color: #ffffff;
  border: 1px solid #e5edf0;
  border-radius: 4px;
  margin-left: 20px;
  display: flex;
  align-items: center;
  height: 28px;
  width: 28px;
  padding-right: 2px;

  span {
    padding-left: 6px;
  }
}

.head {
  @extend .ResTable-cell;
  color: #9b9b9b;
  height: 36px;
  display: flex;
  margin-top: unset;
  margin-bottom: unset;
  position: sticky;
  top: 0;

  &.active {
    color: #000000 !important;
  }

  &.sortable {
    cursor: pointer;
  }

  &:first-child() {
    width: 70%;
    @media screen and (max-width: 1130px) {
      max-width: 50%;
    }
  }

  &:nth-child(1) {
    width: 40%;
    @media screen and (max-width: 1130px) {
      max-width: 30%;
    }
  }

  &:nth-child(2) {
    width: 10%;
  }

  &:nth-child(3) {
    width: 5%;
  }

  &:nth-child(4) {
    width: 5%;
  }
}

.short {
  @extend .ResTable-cell;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  padding-bottom: 0px;
  margin-bottom: 6px;
  width: 40%;
  @media screen and (max-width: 1130px) {
    max-width: 25%;
  }
  cursor: pointer;
}

.answer {
  padding-top: 4px;
}

.question {
  color: #00796b;
  font-weight: 600;
  font-size: 14px;
  padding-bottom: 5px;
  overflow: hidden;
}

.date {
  @extend .ResTable-cell;
  width: 10%;
}

.sml {
  @extend .ResTable-cell;
  width: 5%;
  word-break: break-all;
}

.icons {
  @extend .sml;
  padding-right: unset;
}

.activeBkg {
  background-color: #f9fafb;
}

.accord {
  @extend .ResTable-cell;
  padding: unset;
  margin: unset;
  width: 100%;
  background-color: #f9fafb;
  padding-right: unset;
  justify-content: space-between;
}

.divider {
  border-bottom: 1px inset rgba(155, 155, 155, 0.3);
}

.right.angle.icon.divider {
  border-bottom: none;
}

.bottomArrow {
  align-self: flex-end !important;
}

.FileLabel {
  cursor: pointer;
  display: flex;
  align-items: center;
  // padding-bottom: 10px;

  img {
    position: static;
  }

  span {
    padding: unset;
    color: black;
  }
}

.moreIcon,
.arrowIcon {
  cursor: pointer;
}

.tableImgList {
  display: flex;
  padding-top: 20px;
}

.suggestions {
  background-color: rgba(0, 185, 171, 0.1) !important;
  color: rgba(0, 185, 171, 1) !important;
  font-size: 14px !important;
  border-radius: 6px !important;
  cursor: unset !important;

  &Featured {
    background-color: rgba(0, 185, 171, 1) !important;
    color: #fff !important;
  }
}

.suggestionsList {
  padding-top: 10px;
}

.custom-icon-name {
  &:before {
    content: url("../../assets/more.svg");
  }
}

.mItem {
  color: $primary-vivid !important;
}

.upIcon {
  margin-left: 3px;

  &.desc {
    transform: rotate(-180deg);
  }
}

.sIconLabel {
  background-color: unset !important;

  &.srch {
    cursor: pointer !important;
    padding-right: 0px !important;
  }
}

.filterInput {
  width: 100%;
  flex-basis: 57%;
  align-items: center;
  margin-right: auto;
  border-bottom: 1px solid #e5edf0 !important;

  input {
    border: none !important;
    border-style: none !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  &.active {
    border-bottom: 1px solid $primary-vivid !important;
  }

  i {
    opacity: unset !important;
    color: $primary-vivid !important;
  }
}

.searchMenu {
  z-index: 50 !important;
}

.filterAccordion {
  display: flex;
  padding-bottom: 1.5rem;
  align-items: center;
}

.react-datepicker-popper {
  z-index: 80;
}

.react-datepicker__day--in-selecting-range {
  background-color: #dff1ef !important;
}

.react-datepicker__day--in-range,
.react-datepicker__day--selecting-range-start,
.react-datepicker__day--keyboard-selected {
  background-color: #20bda3 !important;
}

.react-datepicker__current-month {
  color: #020525;
  font-weight: 400 !important;
  line-height: 14px;
}

.react-datepicker__day {
  &:hover {
    background-color: #20bda3 !important;
  }
}

.react-datepicker-wrapper {
  margin-right: 0.7rem;
}

.react-datepicker__header {
  background-color: unset !important;
  border-bottom: 1px solid #cfd0db !important;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}

.react-datepicker__triangle {
  border-bottom-color: white !important;
}

.react-datepicker__input-container input {
  border: unset;
  border-bottom: 1px solid #e5edf0 !important;
  padding: 10px 0;
  font-size: 12px;

  &.act {
    border-bottom: 1px solid $primary-vivid !important;
  }
}

.react-datepicker__navigation--next {
  color: greenyellow !important;
}

.documentsDropDown,
.author,
.timePeriod {
  font-size: 12px !important;
  margin-right: 0.4rem;
  margin-left: 0.4rem;
  border: unset !important;
  border-bottom: 1px solid #e5edf0 !important;
  border-radius: unset !important;

  &.act {
    border-bottom: 1px solid $primary-vivid !important;
    border-color: $primary-vivid !important;
  }
}

.timePeriod {
  margin-right: 2.2rem;
  margin-left: 2.2rem;
}

.searchSection {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
  padding: 0 2.2rem;
  padding-bottom: 20px;

  .moreFiltersButton {
    flex-basis: calc(14% - 5px);
    background-color: unset !important;
    color: $primary-vivid !important;
    padding: 0;
    margin: 0 20px 0 5px;
    text-align: right;

    // margin-left: 23rem !important;
    // width: max-content;

    &:hover {
      background-color: unset !important;
    }
  }

  .addIssueButton {
    min-width: 150px;
    margin-left: auto;
    margin-right: 0;
    margin-top: -2px;
    a {
      color: $light;
    }
  }
}

.filterResetButton {
  display: flex !important;
  background-color: unset !important;
  color: $primary-vivid !important;
  // margin-left: 23rem !important;
  // width: max-content;

  &:hover {
    background-color: unset !important;
  }
}

.menu.transition.top.visible {
  bottom: -27px;
  top: -23px;
  left: 46px;
}
