@use "../../../../../scss/variables" as *;

.wrapper {
  background-color: $superlight;
  border-radius: 0.5rem;
  border: 1px solid $gray;
  padding: 1rem;
  min-width: 200px;
  max-width: 300px;
}

.question {
  font-size: $xs;
  color: $gray;
}
