.group {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 0;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;

  &Control {
    &:global {
      &.react-flow__controls-button {
        box-shadow: none;
      }
    }
  }
}
