.RTEWrapper {
  grid-column: 2 / 4;
  width: 100%;

  :global(.mce-panel) {
    border: none;
    background: transparent;

    @media screen and (min-width: 1920px) {
      max-width: unset;
    }
  }

  :global(.mce-top-part::before),
  :global(.mce-top-part) {
    top: 0;
    position: sticky;
  }

  :global(.mce-top-part) {
    max-width: 960px;
    @media screen and (min-width: 1920px) {
      max-width: 1000px;
      margin: 0 auto;
      padding-right: 247px;
    }
  }
  :global(.mce-tinymce) {
    box-shadow: none;
    margin: 0px 32px;
    width: unset !important;
  }

  :global(.mce-edit-area) {
    max-width: 960px;
    @media screen and (min-width: 1920px) {
      max-width: 1000px;
      margin: 0 auto;
      padding-right: 247px;
    }
  }

  :global(.mce-listbox .mce-caret) {
    right: 2px !important;
  }
}
