@use "../../../../scss/mixins" as *;

.actionsButtonsWrapper {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: 0;

  .shareButton,
  .downloadButton {
    background-color: $superlight;

    &:hover {
      background-color: #f2f2f2;
    }

    &:focus,
    :active {
      box-shadow: none;
    }

    &.disabled {
      cursor: default;

      &:hover,
      &:focus,
      &:active {
        background-color: $superlight;
      }

      .shareIcon,
      .downloadIcon {
        path {
          fill: $gray;
        }
      }
    }
  }

  .shareButton {
    margin-right: 12px;
    .shareIcon {
      width: 16px;
      height: 16px;
    }
  }

  .downloadButton {
    margin-right: 12px;
    .downloadIcon {
      width: 16px;
      height: 16px;
    }
  }
}
