@use "../../../../../scss/variables" as *;

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $gray;
  color: $superlight;
  font-size: $s;

  &Container {
    padding: 0;
    border: none;
    box-shadow: none;
  }

  .icon {
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
  }
}
