@use "../../../scss/variables" as *;
@use "../../../scss/mixins" as mixins;

.content {
  margin-top: 14px;
  margin-bottom: 14px;
  position: relative;

  &Text {
    color: $text-gray;

    :global img {
      max-width: 100%;
    }

    &Hidden {
      position: relative;
      max-height: 200px;
      overflow-y: hidden;

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 160px;
        background-image: linear-gradient(to top, $superlight, transparent);
        pointer-events: none;
      }
    }
  }
}

.showMore {
  position: absolute;
  bottom: -0.5rem;
  left: 50%;
  transform: translateX(-50%);
  margin: 20px auto 0;
  background-color: transparent;
  border: none;
  color: $text-gray;
  text-decoration: underline;
  padding: 0.5em 1em;
  font-size: 12px;
  border-radius: 0.5em;
  font-weight: $thin;

  &:hover,
  &:focus {
    color: $primary;
    background-color: transparent;
  }

  &:focus {
    @include mixins.button-focus-shadow(darken($lightgray, 5%));
  }

  &:hover,
  &:focus {
    background-color: $lighter;
  }

  &:focus {
    @include mixins.button-focus-shadow(darken($lightgray, 5%));
  }

  &Expanded {
    position: relative;
  }
}

.info {
  position: absolute;
  top: -15px;
  right: 0;
  width: 20px;
  height: 20px;
  border: 1px solid $gray;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  :global(i.icon.info) {
    width: unset;
    height: unset;
    margin: 0;
    line-height: 1;
    color: $gray;
  }
}
