@import "../../scss/variables";
@import "../../scss/mixins";

.pdf-component {
  ::-webkit-scrollbar {
    width: 9px !important;
    height: 9px !important;
  }
  overflow-y: hidden;
  overflow-x: hidden;
  height: 100%;
  .pdf-container {
    height: 100%;
    padding: 1px 1rem 1rem;

    &-loading {
      margin-top: 20px;
    }

    &-message {
      width: calc(100% - 20px);
      margin: 20px 10px;
      padding: 15px;
      background-color: rgba(lighten($warning, 15), 0.15);
      color: $warning;
      font-size: 16px;
      border-radius: 10px;
      text-align: center;
    }

    .zoom-group {
      margin: 0 auto;
      position: fixed;
      bottom: 2rem;
      width: 80px;
      left: 0;
      right: 0;

      .zoom-in,
      .zoom-out {
        padding: 5px;
        background-color: #334140;
        width: 40px;
        height: 32px;
        cursor: pointer;

        &:hover {
          background-color: #404f4e;
        }
      }
    }
  }
}

.document-header {
  display: flex;
  justify-content: space-between;
  align-content: center;
  left: 0;
  right: 0;
  background: white;
  min-height: 37px;
  max-height: 110px;
  box-shadow: 0 1px 8px -2px rgba(0, 0, 0, 0.2);
  margin-bottom: 1rem;

  &.without-margin {
    margin-bottom: 0px;
  }

  .group {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #383838;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    margin-left: 36px;
    margin-top: 11px !important;
    margin-bottom: 11px !important;
    padding-left: 5px;

    &:first-child {
      > div,
      > img {
        align-items: center;
        margin-left: 36px;
      }

      img {
        pointer-events: none;
      }
    }

    &:last-child {
      > div,
      > img {
        align-items: center;
        margin: 0 0.75rem;
      }
    }

    img {
      height: 27px;
      width: 26px;
      cursor: pointer;
    }

    img:hover {
      background-color: #d6f6f1;
    }

    .draftLabel {
      white-space: nowrap;
      background-color: $text-gray;
      font-size: 12px;
      font-weight: $normal;
      height: 24px;
      display: flex;
      align-items: center;
    }
  }

  .createTime,
  .author,
  .fileSize,
  .editInfo {
    color: #8e8e8e;
  }
}

.documentView {
  height: 100%;
  margin: 1px 1rem 1rem 1rem;
  overflow-y: auto;

  img {
    max-width: 100%;
    height: auto;
    max-height: 500px;
    resize: vertical;
  }
}

.preview {
  width: 100%;
  height: 98%;
  position: fixed;
  margin-top: 0rem;

  .not-allowed .disabled {
    cursor: not-allowed;
  }

  .__react_component_tooltip.type-light {
    border-radius: 0;
    border: 1px solid #e2e2e2;
    background-color: #ffffff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  }
  //transform: translateY(-25px) translateX(-20px);
}

.preview > .content table {
  border-collapse: collapse;
  width: 100%;
}

.preview > .content td,
.preview > .content th {
  border: 1px solid #ccc;
  padding: 8px;
}

.pdf-container canvas {
  margin: 0 auto;
}

.pdf-container canvas ~ div {
  display: none;
}

.pdf-container .pdf-page:first-child {
  margin: 0 0 39px 0;
}

.pdf-container .pdf-page {
  margin: 39px 0 39px 0;
}

.pdf-component .controls {
  height: 50px;
  z-index: 1;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pdf-component .controls .pagination {
  margin: 0 auto;
}

.preview > .content tr:nth-child(odd) {
  background-color: #ffffff;
}
.preview > .content tr:nth-child(even) {
  background-color: #f2f2f2;
}

.documentTable {
  border: none !important;
  thead {
    th {
      position: sticky;
      top: 0;
      z-index: 3;
    }
  }
  tr {
    td {
      padding-left: 8px !important;
      padding-right: 8px !important;
      border-left: none !important;
      text-align: center;
      &:not(.documentName) {
        color: #8d93ab;
      }
      img {
        text-align: center;
        margin-right: 5px;
      }
      div .buttons {
        border: none !important;
        button {
          border: none !important;
        }
      }
    }
    .documentName {
      padding: 20px 0px !important;
      cursor: pointer;
      word-break: break-word;
      display: flex;
      align-items: center;

      svg {
        min-width: 24px;
        max-width: 24px;
        vertical-align: middle;
      }

      div {
        display: inline;
      }

      .draftNamePlaceholder {
        color: $gray;
        margin-right: 4px;
      }

      .draftLabel {
        white-space: nowrap;
        background-color: $text-gray;
        font-size: 12px;
        font-weight: $normal;
        height: 24px;
        display: flex;
        align-items: center;
      }
    }
  }
}

div.tableScroll {
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.documentTableWrapper {
  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  background-color: #ffffff !important;
  padding: 16px 24px;
  @include main-box-shadow;
  border-radius: 8px;
  display: block;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;

  .documentLocation {
    max-width: 420px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: $dark-blue;
    font-size: 16px;
    font-weight: 400;
  }

  .ui.breadcrumb {
    line-height: unset !important;
    font-size: unset !important;
    margin-top: 8px;

    .divider {
      vertical-align: super !important;
      padding: 0 3px 0 3px;
      border-bottom: none;
    }
  }
}

.documentsHeaderSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .ui.button.addFolder {
    border-radius: 8px !important;
  }

  .ui.button.labeled.dropdown {
    border-radius: 8px !important;
    padding-top: 13px !important;
    padding-bottom: 13px !important;
  }
}

.documentHeaderPath {
  display: flex;

  .backButton {
    background-color: transparent;
    height: 25px;
    padding: 0px;

    &:active,
    &:focus {
      box-shadow: none;
    }
  }

  i.big.icon {
    font-size: 1.5em;
  }
}

.tableHeaders {
  border: none !important;
  background-color: $superlight !important;
  .active {
    color: #020525 !important;
  }
  th {
    font-size: 12px !important;
    border: none !important;
    font-weight: 400 !important;
    color: #9b9b9b !important;
  }
  th:not(.not-sortable) {
    cursor: pointer !important;
  }
}

.ui.table .tableHeaders {
  background-color: $superlight !important;
  th {
    font-size: 12px !important;
    border: none !important;
    font-weight: 400 !important;
    color: #9b9b9b !important;
    background-color: $superlight !important;
    &.active {
      color: #020525 !important;
    }
  }
}

.actionButtons {
  border: none !important;
  button {
    border-left: none !important;
    padding: 0px !important;
  }
  button:hover {
    background: red !important;
  }
  @media screen and (max-width: 1100px) {
    width: 30px !important;
    display: grid !important;
    button {
      display: contents !important;
      margin-bottom: 5px !important;
    }
  }
}

.foldersAction {
  width: 30px !important;
  margin-right: 12px !important;
}

.buttonGroup {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  .moreButton {
    width: 24% !important;
    padding: 8px;
    .item {
      color: $primary-vivid !important;
    }
  }
  .custom-icon-name {
    &:before {
      content: url("../../assets/more.svg");
    }
  }
}

.removeFolder {
  width: 24% !important;
}

.ui.basic.buttons .button:hover {
  background: #f2f2f2 !important;
}

.ui.table,
.document-header {
  font-size: 14px;
  .checkbox {
    display: block;

    &.checked {
      label {
        &::before {
          background: linear-gradient(#1cbda3, #12917c) !important;
          width: 2.5rem !important;
        }
        &::after {
          left: 1.15rem !important;
        }
      }
    }

    label {
      &::before {
        top: 0.25rem !important;
        height: 1rem !important;
        width: 2.5rem !important;
      }

      &::after {
        background: linear-gradient(#0b6757, #12917c) !important;
        left: -0.05rem !important;
      }
    }
  }
}

.customBread {
  color: #8d93ab !important;
  max-width: 120px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: unset !important;
  &.active {
    color: $primary-vivid !important;
  }
  &.customPath {
    cursor: unset !important;
  }
}

.addFolder {
  background: none !important;
  border-radius: 0% !important;
  border: solid 1px $primary-vivid !important;
  padding: 6px 9px !important;
  margin-right: 10px !important;
}

.newFolderName {
  overflow: hidden;
  input {
    border: none !important;
  }
  ::placeholder {
    color: $primary-vivid !important;
  }
  i {
    color: $primary-vivid !important;
  }
}

.changeName {
  width: 100%;
  input {
    border: none !important;
    width: 100%;
  }
  ::placeholder {
    color: $primary-vivid !important;
  }
  i {
    color: $primary-vivid !important;
  }
}

.folderLocation {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 40px;

  svg {
    min-width: 24px;
    max-width: 24px;
  }

  .folderName {
    width: 100%;
    display: block;
  }

  &:hover {
    i {
      color: $primary-vivid !important;
    }
  }
}

.selectedLocation {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #f2f2f2 !important;
  height: 40px;
  i {
    color: $primary-vivid !important;
  }

  svg {
    min-width: 24px;
    max-width: 24px;
  }

  .folderName {
    width: 100%;
    display: block;
  }
}

.moveButton {
  background-color: $primary-vivid !important;
  color: #ffffff !important;
}

.moveButtonWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.moveDivider {
  margin-left: -8px !important;
  margin-right: -8px !important;
}

.moveModal {
  position: absolute !important;
  width: 250px !important;
}

.moveModalContent {
  overflow-y: auto;
  max-height: 200px;
  padding: 5px !important;
  padding-top: 0 !important;
  padding-bottom: 1rem !important;
  margin-top: -15px;
  margin-bottom: -8px;
}

.moveModalHeader {
  background-color: #ffffff !important;
}

.folderName {
  display: inline;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $dark-blue;
  font-size: 14px;
  line-height: 22px;
  margin-left: 5px;
}

.arrowIcon {
  &:hover {
    color: $primary-vivid !important;
  }
}

.upIcon {
  margin-left: 3px;
  &.desc {
    transform: rotate(-180deg);
  }
}

.data-tip {
  color: red !important;
}
