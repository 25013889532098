@import "../../scss/variables.scss";

.descriptionWelcomeLabel {
  background-color: $navy-blue !important;
  color: $superlight !important;
}

.title {
  margin-bottom: 4px;
}

.descriptionAnswerShortened {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 24px;
  font-size: 12px;
  color: $text-gray;
  font-weight: 500;
}

.changesDetailsAnswerContainer {
  max-width: 80%;
  margin-bottom: 20px;

  img {
    max-width: 100%;
  }
}
