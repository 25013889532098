@use "../../scss/mixins" as mixins;

body ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.item {
  padding: 0;
}

.flex {
  display: flex;
  overflow-x: hidden;
  overflow-y: visible;
}

.avatar {
  width: 40px;
  height: 40px;
  flex-basis: 40px;
  flex-grow: 0;
  flex-shrink: 0;
  vertical-align: top;
  display: inline-block;
  margin-top: 5px;
}

.block {
  margin-left: 15px;
  margin-top: 5px;
  max-width: #{"min(75%, 700px)"};
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 30px;

  &HiddenActions {
    & > :global(div.hidden) {
      display: none;
    }

    &:hover {
      & > :global(div.hidden) {
        display: flex;
        animation: fadeIn 0.3s ease-in-out 1 forwards;

        @keyframes fadeIn {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
      }
    }
  }
}

.bubble {
  max-width: 100%;
  display: inline-block;
  padding-right: 5px;
}

.message {
  margin-bottom: 5px;
  padding: 15px 20px;
  border-radius: 0 15px 15px 15px;
  background-color: #f2f6f7 !important;
  color: #383838;
  font-size: 14px;
  display: inline-block;
  max-width: 100%;
  overflow-x: auto;

  @include mixins.commonTextSizes;

  &.messageMiniMargin {
    padding: 10px;
  }

  & > p {
    margin: 0;
  }

  *:not(iframe) {
    height: auto;
  }

  iframe {
    max-width: 100%;
  }
}

.header {
  margin-left: 55px;
  display: block;
  color: #9b9b9b;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}

.headerName {
  display: block;
  color: #9b9b9b;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  cursor: pointer;
  max-width: 180px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:hover {
    color: #00b9ab;
  }
}

.docImg {
  height: 14px;
  width: auto;
  margin-left: 20px;
}

.messageHeader {
  display: flex;
}

.info {
  border-left: 6px solid #458fc7;
}

.actions {
  position: absolute;
  top: 100%;
  right: 0;
}
