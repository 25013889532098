@use "../../../scss/variables" as *;
@use "../../../scss/mixins" as mixins;

.content {
  position: relative;
  border-bottom: 1px solid #e0e4f6;

  &:last-of-type {
    border-bottom: none;
  }

  &QuestionAndAnswer {
    display: flex;
    flex-direction: column;

    &.single {
      border-bottom: none;

      .question {
        margin: 0px;
      }

      .questionWrapper {
        padding: 0px;
      }
    }
    .collapse {
      display: flex;
      justify-content: flex-end;

      :global(.arrowIcon) {
        color: #000000 !important;
      }
    }
    .questionWrapper {
      padding: 8px 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      :global(.arrowIcon) {
        color: #000000 !important;
      }

      .documentAnchor {
        padding: 0.5em 0 0.5em 0.3em;
        margin-right: 0;
      }
    }

    :global(.ReactCollapse--collapse) {
      transition: height 500ms;

      :global(.ReactCollapse--content) {
        padding-bottom: 12px;
      }
    }

    .answerContainer {
      display: none;

      @include mixins.commonTextSizes;

      &.open {
        display: block;
      }
      .answer {
        color: $text-gray;

        img {
          max-width: 100%;
        }
      }
    }
  }

  &Text {
    color: $text-gray;
    padding: 16px 0px;

    img {
      max-width: 100%;
    }

    &Hidden {
      position: relative;
      max-height: 100px;
      overflow-y: hidden;

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 60px;
        background-image: linear-gradient(to top, $superlight, transparent);
        pointer-events: none;
      }
    }
  }
}

.contentActions {
  position: absolute;
  display: inline-block;
  bottom: -0.5rem;
  left: 50%;
  transform: translateX(-50%);
  margin: 20px auto 0;

  :first-child:nth-last-child(n + 3) ~ i::before {
    content: "|";
    font-style: normal;
  }

  &Expanded {
    position: relative;
  }

  &CannotBeExpanded {
    bottom: -0.3rem;
  }

  .showMore {
    background-color: transparent;
    border: none;
    color: $text-gray;
    text-decoration: underline;
    padding: 0.5em 1em;
    font-size: 12px;
    border-radius: 0.5em;
    font-weight: $thin;
    margin-bottom: 12px;

    &:hover,
    &:focus {
      color: $primary;
      background-color: $lighter;
    }

    &:focus {
      @include mixins.button-focus-shadow(darken($lightgray, 5%));
    }
  }

  .documentAnchor {
    font-size: 12px;
    padding: 0.5em 1em;
    margin: 0;
    margin-bottom: 12px;
  }
}
