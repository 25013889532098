@import "../../../scss/variables";

.arrowButton {
  background-color: $primary;
  box-shadow: 0px 1px 4px 2px rgba(0, 0, 0, 0.1);
  padding: 0px;
  border-radius: 100%;
  width: 32px;
  height: 32px;
  position: absolute;
  right: -16px;
  top: 50px;
  z-index: 10000;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  & svg {
    height: 15px;
    width: 15px;
    pointer-events: none;

    g {
      fill: $superlight;
    }

    &.right {
      transform: none;
      margin: 1px 0 0 2px;
    }

    &.left {
      transform: rotate(180deg);
      margin: 1px 2px 0 0;
    }
  }
}
