@import "../../../scss/mixins";
@import "../../../scss/variables";

.button {
  font-size: 14px;
  font-weight: 600;
  border: none;
  background-color: $primary;
  border-radius: 4px;
  padding: 9px 30px;
  color: $superlight;
  outline: none;
  cursor: pointer;
  @include combined-transitions(
    0.125s ease background-color,
    0.125s ease color,
    0.125s ease box-shadow
  );

  &:hover {
    background-color: darken($primary, 1.5%);
  }

  &:focus {
    @include button-focus-shadow($primary);
    background-color: darken($primary, 1.5%);
  }

  &:active {
    background-color: darken($primary, 4%);
  }

  &Secondary {
    background-color: $superlight;
    color: $primary;
    box-shadow: inset 0px 0px 1px $gray;

    &:hover {
      color: $superlight;
      background-color: $primary;
    }

    &:focus {
      color: $superlight;
      @include button-focus-shadow($primary);
      background-color: $primary;
    }

    &:active {
      color: $superlight;
      background-color: darken($primary, 3%);
    }

    &.negative {
      color: $error;
      box-shadow: inset 0px 0px 1px rgba($error, 0.5);

      &:hover {
        color: $superlight;
        background-color: lighten($error, 15%);
      }

      &:focus {
        color: $superlight;
        @include button-focus-shadow($error);
        background-color: lighten($error, 15%);
      }

      &:active {
        color: $superlight;
        background-color: lighten($error, 20%);
      }
    }
  }

  &:disabled {
    opacity: 0.6;
    pointer-events: none;
    cursor: not-allowed;
  }
}
