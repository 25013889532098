@import "../../../scss/variables";
@import "../../../scss/mixins";

.topBar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 58px;
  padding: 12px 50px 10px 272px;
  z-index: 998;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 16px;
  font-weight: 600;
  color: $gray;
  background-color: transparent;
  transition: background-color 0.2s linear;

  &Bg {
    background-color: $superlight;
  }

  &_item {
    padding: 8px 0;
    margin-right: 32px;

    &_active {
      color: $primary;
    }
  }

  &AddIcon {
    padding: 8px;
    opacity: 0.5;

    @include combined-transitions(
      opacity $default-easing-timing,
      box-shadow $fast-easing-timing
    );

    &:hover {
      opacity: 1;
    }

    svg {
      width: 16px;
      height: 16px;
    }
  }

  &_button_tooltip {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.37);
    font-weight: 400;
  }

  &_menu {
    display: flex;
    align-self: center;
    justify-content: flex-end;
    align-items: center;
    margin-left: auto;

    &_icon {
      padding: 6px;
      margin-right: 16px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 20px;
        height: 20px;
        margin: 0;
      }
    }

    &_user {
      &Initials {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 600;
        padding: 10px;
        background-color: $light;
        border-radius: 8px;
        margin-right: 16px;
      }

      &Details {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $dark-blue;
        font-weight: 400;

        &_name {
          font-size: 14px;
        }

        &_email {
          font-size: 12px;
          a {
            color: $gray;
          }
        }

        &_icon {
          margin-left: 16px;
          background-color: transparent;
          padding: 0;
          width: 20px;
          height: 20px;

          svg {
            width: 20px;
            height: 20px;
            margin: 0;

            path {
              transition: 0.2s fill ease;
            }
          }

          &:hover,
          &:focus,
          &:active {
            background-color: transparent;
          }

          &:hover {
            svg {
              &:global(.help) {
                path {
                  fill: $dark-blue;
                }
              }

              &:global(.logout) {
                path {
                  stroke: $dark-blue;
                }
              }
            }
          }
        }
      }
    }
  }
}

@keyframes reduceTopbar {
  from {
    padding-left: $menuLeftPaddingExpanded;
  }
  to {
    padding-left: $menuLeftPaddingReduced;
  }
}

@keyframes expandTopbar {
  from {
    padding-left: $menuLeftPaddingReduced;
  }
  to {
    padding-left: $menuLeftPaddingExpanded;
  }
}

.topBarReduced {
  animation: reduceTopbar $menuAnimationTime;
  padding-left: $menuLeftPaddingReduced;
}

.topBarExpanded {
  animation: expandTopbar $menuAnimationTime;
  padding-left: $menuLeftPaddingExpanded;
}
