div.FileLabel {
  display: inline-flex !important;
  align-items: center;
  padding-right: 6px;
  background: #fff;
  cursor: pointer;

  &:hover {
    background-color: darken(#fff, 3%);
  }

  &.noFilename {
    padding-right: 0;
    img {
      margin-right: 0;
    }
  }
  &.noBorder {
    border: none;
    background: transparent;
  }
  &.bigLabel {
    span {
      font-size: 14px;
    }
  }
  img:global(.ui.rounded.image).FileLabelIcon {
    box-sizing: content-box;
    border-radius: 4px;
    width: 16px;
    height: 16px;
    padding: 6px;
  }
  span {
    font-size: 12px;
    line-height: 14px;
  }
}
