@import "../../../scss/mixins";
@import "../../../scss/variables";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: $superlight;
  @include main-box-shadow;
  border-radius: 8px;

  &Title {
    font-size: 16px;
    font-weight: 400;
    padding: 16px 24px;
    color: $dark-blue;
    margin: 0;
  }

  :global(.overview) {
    align-self: center;
    justify-self: center;
  }
}

:global(.ui.horizontal.segments) {
  border: none;
  margin: 0px;
  box-shadow: none;

  :global(.segment) {
    background-color: $superlight;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-right: 24px;
    border: none;

    :global(.value) {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;

      :global(.loading) {
        position: relative;
        margin: 16px 0px 4px;
        height: 24px;
        width: 100%;
      }

      svg {
        margin-right: 0px;
      }

      span {
        color: $primary;
        font-weight: $normal;
        font-size: 32px;
        margin: 16px 0px 4px;
        line-height: 24px;
      }
    }

    :global(.statistic-label) {
      color: $text-gray !important;
      font-weight: $normal;
      font-size: 16px;
      line-height: 24px;
    }

    &:last-of-type {
      margin-right: 0px;
    }
  }
}

:global(.statistic-tip) {
  color: rgba(0, 0, 0, 0.4);
}

.content {
  padding: 48px 42px;
  background-color: $superlight;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.overview {
  width: 100%;
}

#statistictip {
  box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.37);
}

.usersContainer {
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  margin-top: 20px;
  @include main-box-shadow;
  border-radius: 8px;

  :global {
    .form {
      .field {
        .selection {
          &.dropdown {
            border-color: $light-border;

            &:focus,
            &:active {
              border: 1px solid $primary !important;
            }
          }
        }
      }
    }
  }
}

.usersChart {
  display: flex;
  flex-direction: column;
}
