@import "../../../scss/variables";
@import "../../../scss/mixins";

.table {
  width: 100%;
  height: 100%;
  position: relative;

  &Loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba($superlight, 0.8);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }

  &ScrollContainer {
    height: calc(100% - 140px);
    overflow-y: auto;
  }

  &Head {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: $superlight;
    padding: 0 14px;

    &Item {
      width: 100%;
      align-self: stretch;
      padding: 10px 10px;
      color: $gray;
      font-size: 12px;
      font-weight: 400;
      outline: none;

      &Sortable {
        cursor: pointer;
        @include fast-easing(background-color, color);
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:hover,
        &:focus {
          background-color: $light;
          color: $dark-blue;

          :global {
            #icons_up_small {
              fill: $dark-blue;
            }
          }
        }
      }

      &Icon {
        width: 8px;
        height: 8px;
        transform: rotate(180deg);
        margin: 0;

        :global {
          #icons_up_small {
            @include fast-easing(fill);
            fill: $gray;
          }
        }

        &Ascending {
          transform: rotate(0);
        }

        &Active {
          :global {
            #icons_up_small {
              fill: $dark-blue;
            }
          }
        }
      }

      &Active {
        color: $dark-blue;
      }

      &:global {
        @include table-sizes;
      }
    }
  }

  &Rows {
    width: 100%;
    padding: 0;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    &Row {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      background-color: $superlight;
      padding: 10px 7px 10px 14px;
      border-bottom: 1px solid $light;

      &Item {
        width: 100%;
        align-self: stretch;
        padding: 5px 10px;
        color: $dark-blue;
        font-size: 12px;
        font-weight: 400;
        outline: none;
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;

        &Clickable {
          cursor: pointer;
        }

        &:global {
          @include table-sizes;
        }
      }
    }
  }
}
