@import "../../scss/variables.scss";

a:global(.section).customBread {
  color: #8d93ab;
  max-width: 120px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 10px;
  height: 20px;
  &:hover {
    color: $primary-vivid;
  }
  &.previewBread {
    height: 13px;
  }
  &:global(.active) {
    color: $primary-vivid;
  }
  &:global(.customPath) {
    cursor: unset;
  }
}

div:global(.ui.breadcrumb).breadSection {
  display: flex;
  align-items: center;
  padding: 5px 5px 20px 0;
  font-size: 12px;
  :global(.ui.tiny.label) {
    color: white;
    font-size: 10px;
    padding: 4px 6px 3px 6px;
    display: flex;
    align-items: center;
    img {
      width: 10px !important;
      height: 10px !important;
      margin-top: -2px;
    }
  }
  :global(.divider) {
    padding-top: 0;
  }
}

:global(.preview) .breadSection {
  margin-left: 10px;
}
