@import "../../scss/mixins";
@import "../../scss/variables";

.window {
  background-color: #fff;
  display: flex;
  flex-flow: column;
  border-radius: 8px;
  height: calc(100vh - 184px);
  min-height: 770px;

  .editHeader {
    margin-left: 29px;
    font-size: 16px;
  }
}

.view {
  flex: 1 1 auto;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 14px !important;
  margin-bottom: 14px;
  border-radius: 8px;

  .editAdd {
    font-weight: 400;
    font-size: 16px;
    margin-left: 15px;
  }

  .question {
    font-weight: 400;
    font-size: 16px;
    margin-left: 15px;
  }
}

.bar {
  flex: 0 1 auto;
  background-color: white;
  padding: 20px 40px 20px 40px;
  font-size: 18px !important;
  border-radius: 8px;
}

.right {
  float: right;
}

.editViewBubble {
  border-radius: 4px;
  padding: 8px;
  background-color: #e9f8f6;
  font-size: 14px;
  font-weight: 600;
  color: #01796b;
  display: flex;
  align-items: center;
  text-align: left;

  svg {
    height: 16px;
    margin: 0 0 0 8px;
  }
}

.editViewBlock {
  max-width: min(75%, 700px);
}

.editViewFlex {
  align-items: flex-start;
  margin-left: 15px;
  justify-content: flex-end;
}

.submitButtons {
  background-color: $superlight;
  width: 100%;
  padding: 12px 118px 12px;
  background-color: $primary;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: fixed;
  bottom: 0;
  right: 0;

  &Back {
    margin-right: 10px;
    border: 2px solid transparent !important;

    &:hover,
    &:focus {
      background-color: $lighter !important;
      color: $primary;
    }
  }

  &Save {
    margin-left: 10px;
    border: 2px solid transparent !important;
    box-shadow: none !important;

    &:hover,
    &:focus {
      border-color: $lighter !important;
    }
  }
}
