@import "../../../scss/mixins";
@import "../../../scss/variables.scss";

.reportContainer {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 45px 1rem 20px 1rem;
  background-color: #fff;
  @include main-box-shadow;

  &__heading {
    font-size: 1.5em;
    font-weight: $semi;
    color: $text-gray;
    text-align: center;
    margin-bottom: 32px;
  }

  &__form {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1em 5em 0;

    &__button {
      background-color: $primary;
      color: $superlight;
      min-width: 140px;
      border-radius: 8px;
      white-space: nowrap;
      min-width: unset;
      height: 40px;
      margin-left: 16px;

      &:hover,
      &:focus,
      &:active {
        background-color: darken($primary-vivid, 3%) !important;
      }
    }

    .wrapper {
      position: relative;

      &:first-of-type {
        margin-right: 48px;
      }

      svg {
        position: absolute;
        right: 12px;
        top: 12px;
      }

      .datePicker {
        height: 40px;
        max-width: 288px;
        margin-right: 0px;

        :global {
          .react-datepicker__input-container {
            height: 100%;
            input {
              border-width: thin;
              border: 1px solid $light-border !important;
              border-radius: 4px;
              padding: 8px 0px 8px 8px;
              height: 100%;
              width: 288px;

              &::-webkit-input-placeholder {
                color: $gray-placeholder;
                font-size: 14px;
                font-weight: $thin;
              }

              &:focus,
              &:active {
                border: 1px solid $primary !important;
                outline: none !important;
              }
            }
          }
        }
      }
    }

    @media (max-width: 992px) {
      flex-wrap: wrap;
      margin: 0 auto;

      :global(.react-datepicker-wrapper) {
        flex-basis: 100%;
        margin: 1em auto 0;
      }

      :global(.react-datepicker__input-container) {
        display: flex;
        input {
          margin: 0 auto;
        }
      }

      &__button {
        margin-left: 0 !important;
        margin-top: 1em !important;
      }
    }
  }

  &__disclaimer {
    margin: 2em 0;
    font-size: 12px;
    color: $text-gray-light;
    text-align: center;
    font-weight: $thin;
  }
}
