@import "../../scss/variables";

.issuesPageWrapper {
  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }

  padding: 0;
  display: block;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  overflow-y: hidden;

  .scrollListener {
    width: 100%;
    max-height: calc(100% - 98px);
    position: relative;
    overflow-y: auto;
  }
}

.scrollDummy {
  height: 100%;
}

.issuesTableWrapper {
  display: block;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  overflow: hidden;
}

.tableText {
  color: #000000;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  padding-right: 40px;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  padding-bottom: 0px;
}

.issuesTableRow {
  display: flex;
  flex-wrap: wrap;
  padding-left: 2rem;
  padding-right: 2rem;

  &.issuesTableCellHead {
    @extend .issuesTableCell;
    background-color: $superlight;
    display: flex;
    margin-top: unset;
    margin-bottom: unset;
    z-index: 1;
    .issuesTableCell {
      padding: 11px 0;
      color: #8d93ab;
      overflow: hidden;
    }
  }

  .issuesTableCell {
    @extend .tableText;
    box-sizing: border-box;
    flex-grow: 1;
    padding: 17px 0;
    overflow: visible;
    list-style: none;
    width: 20%;
    align-items: center;
    display: flex;

    &:last-child {
      padding-right: 0px;
    }

    &.active {
      color: #000000;
    }

    &.sortable {
      cursor: pointer;
    }

    &:nth-child(1) {
      width: 25%;
    }

    &:nth-child(2) {
      width: 13%;
    }

    &:nth-child(3) {
      width: 20%;
    }

    &:nth-child(4) {
      width: 15%;
    }

    &:nth-child(5) {
      width: 20%;
    }

    &:nth-child(6) {
      width: 7%;
    }
  }
  .issuesTableCellShort {
    @extend .issuesTableCell;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    padding-bottom: 0px;
    width: 30%;

    @media screen and (max-width: 1130px) {
      max-width: 25%;
    }

    cursor: pointer;
  }
  .issuesTableCellSmall {
    @extend .issuesTableCell;
    width: 5%;
    word-break: break-all;
  }

  .issuesTableCellIcons {
    @extend .issuesTableCellSmall;
    padding-right: unset;
  }
}

.clickable {
  cursor: pointer;
}

.greyText {
  @extend .tableText;
  color: #9b9b9b;
  padding-left: 10px;
}

.nrBox {
  background-color: #ffffff;
  border: 1px solid #e5edf0;
  border-radius: 4px;
  margin-left: 20px;
  display: flex;
  align-items: center;
  height: 28px;
  width: 28px;
  padding-right: 2px;

  span {
    padding-left: 6px;
  }
}

.answer {
  @extend .issuesTableCell;
  @extend .issuesTableCellShort;
  width: 40%;

  @media screen and (max-width: 1130px) {
    max-width: 30%;
  }
}

.date {
  @extend .issuesTableCell;
  width: 10%;
}

.activeBkg {
  background-color: #f9fafb;
}

.divider {
  margin-left: -2.2rem;
  margin-right: -2.2rem;
  padding-top: 0.3rem;
  width: 110%;
  border-bottom: 1px inset rgba(155, 155, 155, 0.3);
}

.right.angle.icon.divider {
  border-bottom: none;
}

.arrowIcon {
  color: $primary-vivid;

  &.accordion {
    padding-left: 10px;
  }
}

.bottomArrow {
  align-self: flex-end;
}

.changesData {
  max-width: 120px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  p > img {
    width: 100%;
    height: auto;
  }

  p > img:hover {
    cursor: zoom-in;
  }
}

.ChangesDetail {
  display: flex;
  flex-direction: column;
  min-width: 50%;
  max-width: 90%;

  span {
    font-size: 12px;
    padding-top: 20px;
    padding-bottom: 10px;
    color: #999999;
  }

  .description {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    max-width: 50%;
  }
}

.FileLabel {
  cursor: pointer;
  display: flex;
  align-items: center;
  img {
    position: static;
  }

  span {
    padding: unset;
    color: black;
  }
}

.moreIcon,
.arrowIcon {
  cursor: pointer;
}

.tableImgList {
  display: flex;
  padding-top: 20px;
}

.suggestions {
  background-color: rgba(0, 185, 171, 0.1);
  color: rgba(0, 185, 171, 1);
  font-size: 14px;
  border-radius: 6px;
  cursor: unset;
}

.suggestionsList {
  padding-top: 10px;
}

.custom-icon-name {
  &:before {
    content: url("../../assets/more.svg");
  }
}

.mItem {
  color: $primary-vivid;
}

.upIcon {
  margin-left: 3px;

  &.desc {
    transform: rotate(-180deg);
  }
}

.sIconLabel {
  background-color: unset;

  &.srch {
    cursor: pointer;
    padding-right: 0px;
  }
}

.filterInput {
  align-items: center;
  margin-bottom: 20px;
  margin-left: 2.2rem;
  margin-right: 10rem;
  width: 64%;
  border-bottom: 1px solid #e5edf0;

  input {
    border: none;
    border-style: none;
    padding-right: 0px;
    padding-left: 0px;
  }

  &.active {
    border-bottom: 1px solid $primary-vivid;
  }

  i {
    opacity: unset;
    color: $primary-vivid;
  }
}

.searchMenu {
  z-index: 50;
}

.filterAccordion {
  display: flex;
  margin-bottom: 1.5rem;
  align-items: center;
}

.timePeriod {
  font-size: 12px;
  width: 15%;
  margin-right: 2.2rem;
  margin-left: 2.2rem;
  border: unset;
  border-bottom: 1px solid #e5edf0;
  border-radius: unset;

  &.act {
    border-bottom: 1px solid $primary-vivid;
    border-color: $primary-vivid;
  }
}

.searchSection {
  display: flex;
  flex-direction: row;
  align-items: center;

  .moreFiltersButton {
    background-color: unset;
    color: $primary-vivid;

    &:hover {
      background-color: unset;
    }
  }
}

button:global(.ui.button).editButton {
  background-color: #20bda3;
  color: #fff;
  border-radius: 8px;
  font-size: 12px;
  height: 40px;
  padding-top: none;
  margin: 0 15px 0 0;
}

.labels {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;

  &Label {
    margin-top: 3px;
    margin-bottom: 3px;

    &:not(:last-child) {
      margin-right: 5px;
    }
  }
}

.filters {
  padding: 20px 24px 20px;

  &Filter {
    max-width: 300px;

    &Title {
      font-size: 12px;
      color: $gray;
      margin: 10px 0;
    }
  }
}

.tags {
  &List {
    button {
      padding: 10px 12px;
    }
  }
}
