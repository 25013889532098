@use "../../../../scss/variables" as *;

.header {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 24px;
  width: 100%;
  padding: 0px 34px 0px 24px;

  .headerItem {
    color: $text-gray-light;
    font-size: 12px;
    font-weight: $normal;
    cursor: pointer;
    user-select: none;

    &.active {
      color: $primary;
    }

    .upIcon {
      margin-left: 5px;
      width: 8px;
      height: 8px;
      fill: $text-gray-light;

      * g {
        fill: $text-gray-light;
      }

      &.descending {
        transform: rotate(-180deg);
      }
    }
  }
}
