@import "../../../scss/variables";

.wrapper {
  margin: 1rem 0;
  width: 100%;
  text-align: left;
  font-size: $s;
  color: $error;
  background-color: rgba($error, 0.05);
  padding: 1rem;
  border-radius: 0.5rem;
}
