@use "../../../scss/variables" as *;

.wrapper {
  width: calc(100% - 40px);
  margin: 2rem 20px;
  border-radius: 1rem;
  padding: 1.5rem 1.5rem 2rem;
  background-color: $light-blue;
  box-shadow: 0px 0px 40px -20px rgba(0, 0, 0, 0.2);
  opacity: 0;
  transform: scale(0);
  animation: popIn ease 0.3s 1 forwards;

  @keyframes popIn {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    80% {
      opacity: 1;
      transform: scale(1.1);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
}

.heading {
  font-size: $s;
  font-weight: $semi;
  color: $text-blue;
  line-height: 1;
  padding-bottom: 1em;
  border-bottom: 2px solid darken($light-blue, 7%);

  &Icon {
    width: $l !important;
    height: $l !important;
    color: $text-blue !important;
    font-size: $s !important;
    border: 1px solid $text-blue !important;
    border-radius: 50% !important;
    display: inline-flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin-right: 1em !important;

    :global(i.icon.info) {
      width: unset;
      height: unset;
      margin: 0;
      line-height: 1;
    }
  }
}

.content {
  font-size: $s;
  margin-top: 2rem;
}
