@import "../../../scss/variables";

.wrapper {
  position: fixed;
  z-index: 2001;
  bottom: 50px;
  left: 25px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: column;
  color: white;
  padding: 20px;
  border-radius: 8px;
  background-color: $primary;
  box-shadow: 0px 2px 40px -15px rgba(0, 0, 0, 0.8);
}

.item {
  font-size: 16px;
  color: $superlight;
  font-weight: bold;
  margin: 0;
}

.separator {
  display: block;
  width: 80%;
  margin: 15px auto;
  height: 2px;
  background-color: $superlight;
}
