.link {
  margin: 0 5px;

  &:hover {
    filter: brightness(0.5);

    i {
      font-weight: bold;
    }
  }
}
