@use "../../../scss/variables" as *;

.wrapper {
  display: grid;
  grid-template-areas:
    "search filters"
    "suggestions filters"
    "results filters";
  grid-template-columns: 1fr 280px;
  grid-template-rows: 48px auto 1fr;
  gap: 1rem;
  height: 100%;

  @media (max-width: 1399px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      "search filtersButton"
      "suggestions suggestions"
      "results results";
  }

  &EmptyMiddle {
    grid-template-areas:
      "search filters"
      "results filters";
    grid-template-rows: 48px 1fr;

    @media (max-width: 1399px) {
      grid-template-areas:
        "search filtersButton"
        "results results";
    }
  }
}

@mixin container {
  background-color: $superlight;
  border-radius: 0.5rem;
  height: 100%;
}

.search {
  grid-area: search;

  @include container;

  &Suggestions {
    grid-area: suggestions;
  }
}

.results {
  grid-area: results;

  @include container;
  background-color: unset;
}

.filters {
  grid-area: filters;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
  border-radius: 8px;

  @media (max-width: 1399px) {
    max-height: 90vh;
    width: 315px;
    max-width: 315px;
    overflow: hidden;
    position: fixed !important;
    top: 32px !important;
    right: 0 !important;
    z-index: 1000 !important;
    transform: translate(110%);
    transition: transform 0.15s ease-in-out;

    &.opened {
      transform: translate(0%);
    }
  }

  @include container;
}

.filtersButton {
  grid-area: filtersButton;
  background-color: #fff;
  border: 1px solid #20bda3;
  border-radius: 8px;
  color: #20bda3;
  display: none;
  align-items: center;
  justify-content: center;
  width: fit-content;

  @media (max-width: 1399px) {
    display: flex;
  }

  svg {
    width: 16px;
    height: 16px;
  }

  path {
    fill: #20bda3 !important;
  }

  &:hover {
    background-color: #f7f9fc;
  }

  &:active {
    background-color: #f7f9fc;
  }

  &:focus {
    background-color: #f7f9fc;
  }
}
