@use "../../../scss/mixins" as mixins;
@use "../../../scss/variables" as *;

.container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: rgba($text-gray, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  padding: 32px;
  z-index: 9199;
}

.content {
  width: auto;
  min-width: min(400px, 25%);
  max-height: calc(100vh - 64px);
  overflow-y: auto;
  @include mixins.vertical-scrollbar;
  padding: 24px;
  border-radius: 0.5rem;
  position: relative;
  background-color: $superlight;
  color: $dark-blue;
  @include mixins.main-box-shadow(rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.1));
}

.header {
  display: block;
  padding-right: 2rem;
  margin-bottom: 1.5rem;
}

.title {
  font-size: $m;
  font-weight: $semi;
  color: $dark-blue;
  margin: 0;
}

.closeButton {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 30px;
  height: 30px;
  background-color: $lightgray;
  padding: 6px;
  border-radius: 50%;

  &:hover,
  &:focus,
  &:active {
    background-color: rgba($error, 0.15);

    :global(svg path) {
      stroke: $error;
    }
  }

  &:focus {
    background-color: rgba($error, 0.2);

    @include mixins.button-focus-shadow($error);
  }
}

.footer {
  width: 100%;
  display: block;
  margin-top: 2rem;

  &Btn {
    display: block;
    width: 100%;
    padding: 16px 30px;

    &:not(:first-child) {
      margin-top: 0.5rem;
    }
  }
}
