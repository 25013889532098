.overlay {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
}

.filtersInnerContent {
  overflow-y: auto;
  // 100vh - app container padding - filtersheader height
  max-height: calc(100vh - 122px - 65px);
  padding-bottom: 2rem;

  @media (max-width: 1399px) {
    padding-bottom: 2rem;
  }
}
