.view {
  flex: 1 1 auto;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 0 24px 0 0 !important;
  margin-bottom: 14px;
}

:global(.ui.list) {
  margin: 0;
}

.view > :first-child {
  margin-top: auto !important;
  background-color: white !important;
  /* use !important to prevent breakage from child margin settings */
}
