@use "../../../../../scss/mixins" as *;

.button {
  &:global {
    &.react-flow__controls-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.1);
      padding: 0;
      border: none;
      border-radius: 4px;
      box-sizing: border-box;

      svg {
        width: 20px !important;
        height: 20px !important;
        margin: 0;
        size: unset;
        max-height: unset;
        max-width: unset;
        @include fast-easing(transform);
      }

      &:hover {
        svg {
          transform: scale(1.2);
        }
      }
    }
  }
}
