@import "../../scss/variables";
@import "../../scss/mixins";

.wrapper {
  background-color: $superlight;
  border-radius: 8px;
  @include main-box-shadow;
  display: flex;
  flex-flow: column;
  // height: calc(100vh - 184px);
  min-height: 770px;
  padding: 0;

  :global(header) {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.actionButtons {
  justify-content: flex-end !important;
}

.suggestedAnswers {
  padding: 0;
  box-shadow: none;
  flex-grow: 1;
  height: 100%;
}

.learnOpened {
  background-color: darken($superlight, 2%) !important;
}

.learnChosen {
  background-color: darken($light, 1%) !important;
}

.chooseAnswerButton {
  font-size: 14px;
  font-weight: 500;
  color: $superlight;
  background-color: $primary;
  border-radius: 4px 0 0 4px;
  padding: 7px 12px;
  border: none;
  outline: none;
  cursor: pointer;
  @include combined-transitions(background-color, box-shadow);

  &Group {
    display: flex;
    align-items: center;
    justify-content: center;

    & > :global(.ui.icon.button) {
      padding: 8px 12px;
      background-color: $primary;
      color: $superlight;
      border-left: 1px solid darken($primary, 5%);

      &:hover,
      &:focus {
        background-color: darken($primary, 1.5%);
      }

      &.active {
        background-color: darken($primary, 4%);
        color: $superlight;
      }

      :global(.item) {
        background-color: $superlight;
        padding: 0 !important;

        > a {
          display: block;
          color: $dark-blue;
          padding: 0.75em 1.15em;
        }

        &:hover,
        &:focus {
          background-color: $lighter;
        }
      }
    }
  }

  &:hover,
  &:focus {
    background-color: darken($primary, 1.5%);
    color: $superlight;
  }

  &:active {
    background-color: darken($primary, 4%);
    color: $superlight;
  }

  &:disabled {
    background-color: darken($primary, 4%);
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.submitButtons {
  background-color: $superlight;
  width: 100%;
  padding: 12px 118px 12px;
  background-color: $primary;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: fixed;
  bottom: 0;
  right: 0;

  &Back {
    margin-right: 10px;
    border: 2px solid transparent !important;

    &:hover,
    &:focus {
      background-color: $lighter !important;
      color: $primary;
    }
  }

  &Save {
    margin-left: 10px;
    border: 2px solid transparent !important;
    box-shadow: none !important;

    &:hover,
    &:focus {
      border-color: $lighter !important;
    }
  }
}

.changesCopy {
  border-radius: 0px 0px 8px 8px !important;
  box-shadow: none !important;

  &TableScroll {
    min-height: 370px;
  }
}

.infoSection {
  width: 100%;
  padding: 10px 24px 24px;
  margin-bottom: 20px;
  background-color: $superlight;
  border-bottom: 1px solid darken($light, 3%);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;

  &Title {
    font-size: 12px;
    color: #8d93ab;
    font-weight: 500;
    text-transform: uppercase;
    flex-basis: 100%;
    margin: 0;
    padding-bottom: 16px;

    &Filters {
      padding-left: 24px;
    }
  }

  &Container {
    width: 100%;
    flex-basis: calc(28% - 10px);

    &Title {
      font-size: 12px;
      color: #8d93ab;
      font-weight: 500;
      margin: 0 0 4px;

      &Label {
        margin-bottom: 10px;
      }
    }

    &User {
      flex-basis: 44%;
      margin: 0;
    }
  }

  &Content {
    font-size: 14px;
    color: $dark-blue;
    font-weight: 500;
    margin: 0;

    &Question {
      font-weight: 600;
    }
  }

  &Labels {
    margin-top: 20px;

    &Input {
      min-width: 200px;
    }
  }
}

.buttonsSection {
  padding: 0 0 24px 0;
  margin: 0 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.button {
  width: auto;
  min-width: 290px;
  padding: 15px;
  margin: 0;
  border-radius: 5px;
  border: 1px solid #dcdee9;
  background-color: $superlight;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  outline: none;
  @include default-easing(background-color, border-color, box-shadow);

  &:first-child {
    margin-right: 15px;
  }

  &:hover,
  &:focus {
    background-color: $lighter;
    border-color: $primary;
  }

  &:active {
    background-color: $light;
    @include button-focus-shadow;
  }

  &:disabled {
    cursor: not-allowed;
    pointer-events: none;
  }

  &Active {
    border-color: $primary;
    border-width: 2px;
    box-shadow: 0px 2px 4px 0px rgba(204, 218, 238, 0.2);
  }

  &Icon {
    width: 27px;
    height: 27px;
    margin: 0;

    &Choose {
      path {
        fill: $primary;
      }
    }
    &New {
      path {
        stroke: $primary;
      }
    }
  }

  &Container {
    width: 100%;
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    &Title {
      margin: 0;
      padding: 0;
      font-size: 14px;
      font-weight: 600;
      color: $dark-blue;
    }

    &Description {
      font-size: 12px;
      font-weight: 400;
      color: #8d93ab;
      margin: 0;
      padding: 0;
      line-height: 1;
    }
  }
}
