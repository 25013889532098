@use "../../../../scss/mixins" as *;

@keyframes expand {
  from {
    max-width: 0px;
    opacity: 0;
  }
  to {
    max-width: 247px;
    opacity: 1;
  }
}

@keyframes reduce {
  from {
    max-width: 247px;
    opacity: 1;
  }
  to {
    max-width: 0px;
    opacity: 0;
  }
}

.wrapper {
  grid-row: 1/3;
  grid-column: 2/3;
  border-left: 1px solid $light-border;

  &.opened {
    animation: expand 0.1s linear;
    max-width: 280px;
    opacity: 1;
  }

  &.closed {
    animation: reduce 0.1s linear;
    max-width: 0px;
    opacity: 0;
    position: absolute;
    left: -1000px;

    .header {
      height: 0px;
    }
  }

  .header {
    height: 90px;
    padding: 16px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $light-border;

    .icon {
      min-width: 20px;
      display: flex;
      svg {
        width: 16px;
        height: 16px;
      }
    }

    .heading {
      font-size: 16px;
      font-weight: $thin;
      color: $text-gray;
      margin-left: 14px;
      white-space: nowrap;
    }

    .tip {
      margin-left: auto;
      .closeButton {
        background-color: rgba(141, 147, 171, 0.3);
        height: fit-content;
        border-radius: 50%;
        margin-left: 16px;
        width: 24px;
        height: 24px;
        padding: 0px;
        @include default-easing(background-color);

        .icon {
          width: 14px;
          height: 14px;
        }

        &:hover {
          background-color: darken($superlight, 5%);
        }

        &:focus {
          @include button-focus-shadow(darken($superlight, 5%));
        }
      }
    }
  }

  .content {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 214px);

    .error {
      margin: 12px;
      width: auto;
    }

    .loader {
      border-bottom: 1px solid $light-border;
      margin-bottom: 4px;
      padding: 12px;

      &:last-of-type {
        border-bottom: 0px;
      }
    }

    .item {
      width: 272px;
      padding: 12px;
      border-bottom: 1px solid $light-border;
      margin-bottom: 4px;

      &:last-of-type {
        border-bottom: 0px;
      }

      .time {
        font-size: 12px;
        font-weight: $thin;
        color: $gray;
        margin-bottom: 8px;
      }

      .description {
        font-size: 12px;
        font-weight: $thin;
        color: $text-gray;

        span {
          font-weight: $normal;
        }
      }
    }
  }
}
