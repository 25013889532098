@import "../../../../scss/variables";

.modal {
  border-radius: 8px !important;

  .wrapper {
    padding: 16px 16px 24px 16px;

    .errorMessage {
      margin: 20px;
      width: unset;
    }

    .heading {
      font-weight: $normal;
      font-size: 14px;
      color: $text-gray;
      text-align: center;
      line-height: 24px;
      max-width: 400px;
      margin: 0 auto;

      strong {
        font-weight: $semi;
      }

      .overwrite {
        font-weight: $semi;
        color: $error;
      }
    }

    .buttons {
      margin-top: 24px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 10px;

      .continueButton,
      .cancelButton {
        height: 40px;
        width: 100%;
        border-radius: 8px;
      }

      .cancelButton {
        background-color: $light;
        border: 1px solid $primary;
        color: $primary;

        &:hover,
        &:focus {
          background-color: $primary;
          color: $light;
        }
      }

      .continueButton {
        border: 2px solid transparent;
        box-shadow: none;
        background-color: $primary;
        color: $light;

        &:hover,
        &:focus {
          background-color: darken($primary, 1.5%);
        }
      }
    }
  }
}
