@import "../../scss/mixins";
@import "../../scss/variables";

.globalPageWrapper {
  background-color: #ffffff !important;
  padding: 0;
  display: block;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  @include main-box-shadow;
  border-radius: 8px;
}

body {
  @include vertical-scrollbar(7px, 7px);
}
