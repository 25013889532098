@use "../../../scss/variables" as *;
@use "../../../scss/mixins" as mixins;

.supporting {
  padding-top: 16px;

  &Input {
    margin-bottom: 16px;
  }

  &Editor {
    border-radius: 0.5rem;
    padding: 14px;
    background-color: $light-bg;
  }
}

.modal {
  &Footer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    &Btn {
      width: auto;
      min-width: 220px;
      display: inline-block;

      margin-top: 0.5rem;

      &:first-of-type {
        margin-left: 0.5rem;
        margin-right: 0;
      }
    }
  }
}
