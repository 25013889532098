@import "../../../scss/variables.scss";

.external {
  margin: 4px 8px 0;
  display: flex;

  & > a > i {
    margin-left: 5px !important;
    color: $primary-vivid;
    font-size: 16px !important;
  }
}
