@import "../../../scss/variables";

.container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  padding: 10px 0;
}

.bubble {
  display: inline-block;
  max-width: 80%;
  width: #{"min(500px, 80%)"};
  font-size: 14px;
  font-weight: 400;
  color: $dark-blue;
  background-color: $light;
  border-radius: 8px 0px 8px 8px;
  padding: 10px 10px;

  :global(.ui.input) {
    font: inherit;
    width: 100%;

    input {
      border-radius: 4px;
    }
  }
}

.avatar {
  display: block;
  width: 40px;
  height: 40px;
  margin-left: 10px;
}
