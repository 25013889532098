@use "../../../scss/mixins" as *;

.newArticleWrapper {
  display: grid;
  grid-template-columns: 1fr 280px;
  grid-template-rows: 90px 1fr;
  height: 100%;
  overflow-y: hidden;
  flex-direction: column;
  background-color: $superlight;
  border-radius: 8px;
  @include main-box-shadow;

  &.updatesHidden {
    display: flex;
  }

  .loading {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .errorMessage {
    margin: 20px;
    width: unset;
  }

  .editor {
    display: flex;
    height: 100%;
    padding: 0px 0px 0px 28px;
    position: relative;
    grid-column: 1 / 2;

    &.withContents {
      display: grid;
      padding: 0px;
      grid-template-columns: 247px 1fr;
      margin-top: 0px;
    }
  }
}
