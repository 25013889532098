@import "../../scss/variables";

.formStep {
  &Title {
    font-size: 24px;
    font-weight: bold;
    color: $primary;
    text-align: left;
    margin-bottom: 0;
  }

  &Desc {
    text-align: left;
    font-size: 14;
    color: $gray;
    margin-top: 0;
    margin-bottom: 10px;
    font-weight: 400;
  }
}

.botNewMessage {
  margin-top: 20px;
  margin-left: 0;
}

.botMessageContainerError {
  :global(.left-message-editor-container) {
    border: 1px solid #e0b4b4;
    background-color: #fff6f6 !important;
  }
}
