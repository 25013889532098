@use "../../../scss/variables" as *;
@use "../../../scss/mixins" as mixins;

.tooltip {
  &:global(.__react_component_tooltip) {
    font-family: "Poppins", sans-serif;
    font-size: $xs;
    color: $light;
    background-color: $text-gray;

    &:global(.place-right) {
      @include mixins.multipleShadow(
        0px 10px 14px rgba($lightgray, 0.05),
        -5px 0px 14px 2px rgba($lightgray, 0.15)
      );
      &::after {
        border-right-color: $text-gray;
      }
    }

    &:global(.place-top) {
      @include mixins.multipleShadow(
        0px 10px 14px rgba($lightgray, 0.05),
        0px 5px 14px 2px rgba($lightgray, 0.15)
      );
      &::after {
        border-top-color: $text-gray;
      }
    }

    &:global(.place-left) {
      @include mixins.multipleShadow(
        0px 10px 14px rgba($lightgray, 0.05),
        5px 0px 14px 2px rgba($lightgray, 0.15)
      );
      &::after {
        border-left-color: $text-gray;
      }
    }

    &:global(.place-bottom) {
      @include mixins.multipleShadow(
        0px 10px 14px rgba($lightgray, 0.05),
        0px 5px 14px 2px rgba($lightgray, 0.15)
      );
      &::after {
        border-bottom-color: $text-gray;
      }
    }
  }

  &.light {
    &:global(.__react_component_tooltip) {
      color: $text-gray;
      background-color: $lightgray;

      &:global(.place-right) {
        @include mixins.multipleShadow(
          0px 10px 14px rgba($text-gray, 0.05),
          -5px 0px 14px 2px rgba($text-gray, 0.15)
        );
        &::after {
          border-right-color: $lightgray;
        }
      }

      &:global(.place-top) {
        @include mixins.multipleShadow(
          0px 10px 14px rgba($text-gray, 0.05),
          0px 5px 14px 2px rgba($text-gray, 0.15)
        );
        &::after {
          border-top-color: $lightgray;
        }
      }

      &:global(.place-left) {
        @include mixins.multipleShadow(
          0px 10px 14px rgba($text-gray, 0.05),
          5px 0px 14px 2px rgba($text-gray, 0.15)
        );
        &::after {
          border-left-color: $lightgray;
        }
      }

      &:global(.place-bottom) {
        @include mixins.multipleShadow(
          0px 10px 14px rgba($text-gray, 0.05),
          0px 5px 14px 2px rgba($text-gray, 0.15)
        );
        &::after {
          border-bottom-color: $lightgray;
        }
      }
    }
  }

  &.small {
    &:global(.__react_component_tooltip) {
      font-size: $xxs;
    }
  }
}
