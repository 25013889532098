@use "../../../scss/variables" as *;

.wrapper {
  padding: 8px 0px;
  margin: 0px 16px;

  h6 {
    color: $gray;
    font-weight: $semi;
    font-size: 14px;
    margin: 0;
  }

  .search {
    width: 100%;
    margin-top: 8px;

    :global(.input) {
      input {
        border: 1px solid #dcdee9 !important;
        border-radius: 8px !important;
      }
    }

    :global(.results) {
      overflow-y: auto;
      max-height: 300px;
      z-index: 999;
      bottom: 110%;
      top: unset;
    }
  }
}
