@import "../../../scss/variables";

.contents {
  flex-basis: 200px;
  // (modalHeigh - document header height - paddings and margins - breadcrumbs - modal header)
  max-height: calc(95vh - 50px - 6.1rem - 28px - 17px);
  border-right: 1px solid $line-gray;
  border-radius: 0;
  background-color: $light;
  box-shadow: none;
  padding: 0 0.5em;
  overflow-y: auto;
  overflow-x: hidden;
  align-self: stretch;

  &Header {
    padding: 1em 0;
    min-height: unset;

    &Title {
      font-size: $xs;
      color: $text-gray;
      text-align: center;
      width: 100%;
    }
  }
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;

  &Item {
    &:not(:first-of-type) {
      margin-top: 0.5em;
    }

    &Anchor {
      color: $gray;
      background-color: transparent;
      border: none;
      text-align: left;
      font-size: $xs;
      line-height: 1.25;
      cursor: pointer;

      &:hover,
      &Active {
        color: $primary;
      }

      &Level {
        &-1 {
          padding-left: 0;
          font-weight: $normal;
        }

        &-2 {
          padding-left: 8px;
        }

        &-3 {
          padding-left: 16px;
        }

        &-4 {
          padding-left: 24px;
        }

        &-5 {
          padding-left: 32px;
        }

        &-6 {
          padding-left: 40px;
        }
      }
    }
  }
}
