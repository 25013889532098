@import "../../../scss/variables";
@import "../../../scss/mixins";

.container {
  display: flex;
  justify-content: center;
  align-items: center;

  &:not(:last-of-type) {
    border-bottom: 1px solid $line-gray;
  }
}

.term {
  flex-basis: 25%;
  padding: 1rem 0;
  font-size: $xs;
  font-weight: $semi;
}

.inputWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  flex-basis: calc(75% - 1rem - 48px);
  margin-left: 1rem;
  margin-top: 0;
  padding: 1.5rem 0;
}

.input {
  order: 2;
  :global label {
    margin-right: 0;

    > span {
      top: -18px;
    }

    & > input {
      padding: 8px;
      font-size: $xxs;
      line-height: 1;
    }
  }

  :global button {
    width: 36px;
    flex-basis: 36px;
    height: 36px;
  }
}

.synonymsList {
  order: 1;
  margin: 0 0.5rem 0 0;

  :global li {
    border: none !important;
    margin: 0.3rem 0.5rem 0.25rem 0 !important;
  }
}

.removeTerm {
  flex-basis: 38px;
  margin-left: 10px;

  &.removeBtn {
    &:hover {
      background-color: rgba($error, 0.15);
    }

    &:focus {
      background-color: rgba($error, 0.15);
      @include button-focus-shadow(lighten($error, 35%));
    }

    &:active {
      background-color: rgba($error, 0.3);
      @include button-focus-shadow(lighten($error, 35%));
    }
  }

  &Icon {
    & > :global(g > g) {
      fill: $error;
    }
  }

  &Tooltip {
    background-color: $light !important;
    color: $dark-blue !important;
    @include shadow(rgba(0, 0, 0, 0.15), 0px, 2px);

    &:global(::after) {
      border-top-color: $light !important;
    }
  }
}
