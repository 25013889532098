@import "../../../scss/variables";
@import "../../../scss/mixins";

.container {
  width: 100%;
  padding: 24px 24px 32px;
  position: relative;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 0 32px;
}

.heading {
  font-size: $m;
  line-height: $l;
  color: $dark-blue;
  font-weight: $semi;
  margin: 0;
  flex-basis: calc(100% - 35px);
}

.closeBtn {
  width: 30px;
  height: 30px;
  background-color: rgba($gray, 20%);
  border-radius: 50%;
  padding: 0;
  flex-basis: 30px;

  &:hover {
    background-color: rgba($error, 20%);
  }

  &:focus {
    background-color: rgba($error, 20%);
    @include button-focus-shadow(lighten($error, 30%));
  }

  &:active {
    background-color: rgba($error, 40%);
    @include button-focus-shadow(lighten($error, 30%));
  }

  &Svg {
    width: 12px;
    height: 12px;
  }
}

.description {
  flex-basis: 100%;
  font-size: $xs;
  line-height: $l;
  font-weight: $thin;
  color: $dark-blue;
  margin: 0.5rem 0 0;
}

.button {
  width: 100%;
  margin-top: 12px;
  min-height: 48px;

  &:first-of-type {
    margin-top: 48px;
  }
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
}

.error {
  margin-top: 30px;
  margin-bottom: 0;
}
