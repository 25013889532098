@use "../../../scss/variables" as *;

.noResults {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &Text {
    font-size: $s;
    color: $text-gray;
    font-weight: $thin;
    line-height: 1.5em;
    margin: 0 0 70px;
  }

  &Image {
    margin: 0;
    width: 100%;
    max-width: 425px;
  }
}

.loader {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 8px 8px 0px;

  &Results {
    font-size: $xxs;
    color: $text-gray;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  label {
    color: $text-gray;
    font-size: 12px;

    input {
      margin-right: 4px;
    }
  }
}
