@use "../../../../../scss/variables" as *;
@use "../../../../../scss/mixins" as mixins;

.wrapper {
  border: none;
  @include mixins.shadow(rgba(0, 0, 0, 0.2), 0px, 0px, 4px, 0px);

  h4 {
    font-size: $xxs;
    color: $gray;
    font-weight: $normal;
    margin: 0;
  }

  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 5.5px;
    border-radius: 6px;

    svg {
      margin: 0 10px 0 0;
      width: 16px;
      height: 16px;
    }

    &:not(:first-child) {
      margin-top: 8px;
    }

    &Knowledge {
      .knowledgeIcon {
        path {
          fill: $superlight;
        }
      }
    }
  }

  .removeButton {
    position: absolute;
    top: -8px;
    right: -8px;
    border-radius: 50%;
    padding: 4px;
    background-color: $lightgray;
    display: none;

    svg {
      width: 16px;
      height: 16px;
    }

    &:hover,
    &:focus {
      background-color: rgba($error, 0.15);

      svg {
        path {
          stroke: $error;
        }
      }
    }

    &:focus {
      @include mixins.button-focus-shadow(rgba($error, 0.15));
    }
  }

  &:hover {
    .removeButton {
      display: flex;
    }
  }
}
