.addButtons {
  display: flex;
}

.addDocumentButton {
  color: white !important;
  background-color: #20bda3 !important;
  border-radius: 0% !important;
  font-size: 14px !important;
  height: 40px;
  padding-top: none;
  margin: 0 0 0 3.5px !important;

  :global(.divider) {
    border-bottom: none;
  }
}
