@import "../../scss/variables.scss";

.learnChosen {
  background-color: darken($light, 1%) !important;
  border-left: 3px solid $primary;
}

.ChangesDetail {
  display: flex;
  flex-direction: column;
  min-width: 50%;
  max-width: 90%;

  .descriptionLabel {
    font-size: 12px;
    padding-top: 20px;
    padding-bottom: 10px;
    color: #999999;
  }

  .description {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    max-width: 50%;
  }
}

.FileLabelWrapper {
  margin-right: auto;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .location {
    color: $gray;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;

    svg {
      margin: 0 6px;
    }
  }
}

.elementsFound {
  flex-basis: calc(28% - 15px);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 5px 0 10px;
  padding: 0;
  color: #000;
  font-size: 1em;
  text-align: center;

  &__count {
    display: inline-block;
    font-size: 1em;
    margin-left: 5px;
    font-weight: 600;
    text-align: center;
  }
}

.changesDetails {
  width: 100%;
  padding-top: 10px;
  position: relative;
  cursor: auto;

  &Close {
    position: absolute;
    bottom: 10px;
    right: 28px;
  }

  &Suggestions {
    margin-bottom: 15px;

    &Title {
      font-weight: 700;
    }

    :global(.suggestions) {
      margin: 4px 4px 4px 0px;
    }
  }
}

.descriptionLabel {
  font-size: 12px;
  padding: 10px 0;
  margin: 0;
  color: #999999;
}

.rowButtonsContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;

  &Wrap {
    flex-wrap: wrap;

    &Button {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.changesRowActive {
  background-color: $lighter !important;

  .changesDetails {
    background-color: $lighter;
  }
}

.changesRowItem {
  .authorDateContainer {
    display: flex;
    flex-direction: column;

    .date {
      font-weight: 500;
      font-size: 12px;
      color: $gray;
    }

    .author {
      font-weight: 600;
      font-size: 12px;
      color: $gray;
    }
  }

  &:nth-child(2) {
    flex-direction: column;
    align-items: baseline;
  }
  &:nth-child(4) {
    margin-left: auto;
  }
  &:first-child {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &:last-child {
    justify-content: end;
    align-items: end;
  }
}

.sortByContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  position: relative;

  .sortByDropdown {
    position: unset;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    :global(.divider) {
      border-bottom: none;
      font-weight: 600;
      color: $text-gray;
      white-space: nowrap;
    }
    :global(.menu) {
      right: 0;
      left: unset;

      :global(.active.selected.item) {
        background: none;
      }
    }
  }
  .sortBy {
    margin-right: 8px;
  }
}

.arrowIcon {
  color: $primary-vivid;

  &.accordion {
    padding-left: 10px;
  }
}

.anchorsLink {
  color: $primary-vivid;
  font-size: 18px;
}
