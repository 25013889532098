@import "../../../scss/variables";

.search {
  width: 50%;

  :global .results {
    width: 100% !important;
  }
}

.input {
  width: 100%;

  &:global(.ui.input) > :global(input) {
    border: none !important;
    border-bottom: 1px solid $line-gray !important;
    border-radius: 0 !important;
    padding-left: 30px !important;
    padding-right: 1em !important;
  }

  &NoIcon {
    &:global(.ui.input) > :global(input) {
      padding-left: 1em !important;
    }
  }

  &.withClear {
    &:global(.ui.input) > :global(input) {
      padding-right: 1.5em !important;
    }
  }

  :global(i.icon) {
    right: unset !important;
    left: 0 !important;
    color: $primary;
    max-width: 30px !important;
  }
}

.clear {
  &:global(.icon.trash) {
    right: 0 !important;
    left: unset !important;
    cursor: pointer !important;
    max-width: 20px !important;
    pointer-events: all !important;
  }
}
