.item {
  padding: 20px;
}

.bubble {
  position: relative;
  background: #ddfffc;
  border-radius: 15px;
  padding: 14px 25px;
}

.bubble:before {
  content: "";
  position: absolute;
  top: 0;
  left: 5%;
  width: 0;
  height: 0;
  border: 15px solid transparent;
  border-bottom-color: #ddfffc;
  border-top: 0;
  border-left: 0;
  margin-left: -15px;
  margin-top: -15px;
}

.header {
  color: #00b9ab;
  font-size: 12px;
  font-weight: 500;
  margin: 5px 0;
}

.comment {
  color: #919191;
  font-size: 14px;
  margin: 7px 0;
}
