.input {
  > div:global(.ui) {
    display: flex !important;
  }

  & > :global(.results) {
    position: absolute !important;
    width: auto !important;
    right: 24px;
    border: none !important;
    border-radius: 8px !important;

    & > :global(.result.active) {
      background-color: rgba(32, 189, 163, 0.1) !important;
    }

    & > :global(.result) {
      &:hover {
        background-color: rgba(32, 189, 163, 0.1) !important;
      }
    }

    & > div {
      & > span.context {
        color: #8d93ab;
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        display: flex;
        flex-wrap: wrap;
        padding: 6px 0px;

        span {
          display: flex;
          align-items: center;

          .folderIcon {
            width: 12px;
            height: 12px;
            margin-right: 8px;

            path {
              fill: #8d93ab !important;
            }
          }
        }
        & > :global(.result) {
          padding: 0.85714286em 1.14285714em !important;
          margin-left: 0px !important;
          border: none !important;

          svg {
            width: 22px;
            fill: #8d93ab;
          }
        }
      }
      .regularSuggestion {
        font-weight: 600 !important;
        padding: 0px !important;
      }

      & > div.matchingContainer {
        font-weight: 600 !important;
        padding: 0px !important;
        font-size: 14px !important;
        & > :global(b[data-matching="true"]) {
          font-weight: 600 !important;
          font-size: 14px !important;
        }
      }
    }
  }
}

.input:global(.results-top) > :global(.results) {
  top: auto !important;
  bottom: 72px;
}
