@use "../../../../scss/variables" as *;

.modal {
  border-radius: 6px !important;
  .content {
    padding: 20px;

    h4 {
      font-weight: $bold;
    }

    h5 {
      font-weight: $thin;
      margin-top: 8px;
    }

    .footer {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 24px;

      .button {
        border-radius: 8px;
        height: 40px;

        &.confirm {
          color: $primary;
          background-color: $lighter;
          border: 2px solid $primary !important;

          &:hover,
          &:focus,
          &:active {
            background-color: $primary !important;
            color: $lighter;
          }
        }
      }
    }
  }
}
