@import "../../../scss/variables";
@import "../../../scss/mixins";

.wrapper {
  padding: 16px 0px;
  margin: 0px 16px;
  border-bottom: 1px solid #e0e4f6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1399px) {
    padding: 16px 0px;
  }

  .filtersCountAndClearContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    .filtersCount {
      color: #3d3d3d;
      font-weight: 600;
      font-size: 14px;
      display: flex;
      align-items: center;
      svg {
        width: 16px;
        height: 16px;
        margin-right: 8px;
      }
    }

    .filtersClear {
      display: flex;
      .closeButtonContainer {
        width: 100%;
        display: none;
        align-items: center;
        justify-content: flex-end;
        margin-left: 8px;

        @media (max-width: 1399px) {
          display: flex;
        }
        .closeButton {
          background-color: $superlight;
          @include default-easing(background-color);

          &:hover {
            background-color: darken($superlight, 5%);
          }

          &:focus {
            @include button-focus-shadow(darken($superlight, 5%));
          }
        }
      }
      .clearButton {
        display: flex;
        align-items: center;
        background-color: transparent;
        font-weight: 300;
        font-size: 12px;
        padding: 8px 4px;
        font-size: 12px;
        font-weight: 400;
        color: #8d93ab;

        &:hover {
          color: #aa1a1a;
        }

        &:focus {
          box-shadow: none;
          text-decoration: underline;
        }

        &:active {
          box-shadow: none;
          text-decoration: underline;
        }

        svg {
          width: 16px;
          height: 16px;

          path {
            stroke: #aa1a1a;
          }
        }
      }
    }
  }
}
