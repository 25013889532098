.form {
  background-color: white !important;
}

.input {
  padding: 16px 24px 16px 0 !important;

  > div:global(.ui) > input {
    border-style: none !important;
    padding: 0 !important;
  }
}
