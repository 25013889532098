@use "../../../scss/variables" as *;
@use "../../../scss/mixins" as mixins;

.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  padding: 8px;
  list-style: none;
}

.breadcrumb {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  outline: none;
  border-radius: 4px;

  &:focus {
    box-shadow: 0px 0px 0px 2px $primary;
  }

  &Link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    outline: none;
    border-radius: 4px;
    padding-right: 4px;

    &:focus {
      box-shadow: 0px 0px 0px 2px $primary;

      .breadcrumbText {
        color: $primary;
      }
    }
  }

  &Icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    margin-right: 4px;

    :global svg {
      max-width: 10px;
      max-height: 10px;
      margin: 0;
    }
  }

  // for now we want only document Icon to be colored
  &:not(:last-child) {
    .breadcrumbIcon {
      filter: grayscale(1);
    }
  }

  &Text {
    color: $gray;
    font-size: $xxs;
    line-height: 1;
    @include mixins.fast-easing(color);
  }

  &Shorthand {
    cursor: pointer;

    &:hover {
      color: $primary;
    }
  }

  &Doc {
    cursor: pointer;
    padding-right: 4px;

    &:hover,
    &:focus {
      .breadcrumbText {
        color: $primary;
      }
    }
  }

  &Separator {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 2px;

    :global svg {
      margin: 0;
      width: 20px;
    }
  }
}
