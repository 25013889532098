@import "../../../scss/mixins";
@import "../../../scss/variables";

.buttonIcon {
  background-color: $primary;
  border: none;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px;
  margin: 0;
  cursor: pointer;
  transition: box-shadow $fast-easing-timing;
  outline: none;

  &.iconMode {
    background-color: transparent;
  }

  &:focus {
    @include button-focus-shadow;
  }

  &:hover {
    background-color: darken($primary, 1.5%);
  }

  &:active {
    background-color: darken($primary, 4%);
  }

  &.secondary {
    background-color: $lightgray;

    &:focus {
      @include button-focus-shadow($line-gray);
    }

    &:hover {
      background-color: darken($line-gray, 1.5%);
    }

    &:active {
      background-color: darken($line-gray, 4%);
    }
  }

  &.darken {
    background-color: $gray;

    &:focus {
      @include button-focus-shadow($gray);
    }

    &:hover {
      background-color: darken($gray, 3%);
    }

    &:active {
      background-color: darken($gray, 5%);
    }
  }

  &.negative {
    &:focus {
      @include button-focus-shadow($errorBg);

      svg {
        filter: brightness(123);
      }
    }

    &:hover {
      background-color: darken($errorBg, 1.5%);

      svg {
        filter: brightness(123);
      }
    }

    &:active {
      background-color: darken($errorBg, 4%);

      svg {
        filter: brightness(123);
      }
    }
  }

  svg {
    padding: 0;
    margin: 0;
  }
}
