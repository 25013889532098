@import "../../scss/variables";
@import "../../scss/mixins";

@keyframes tagAppear {
  from {
    opacity: 0;
    transform: scale(0.3);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.checkboxSuggestions {
  &:global(.checkbox) {
    margin-bottom: 5px;
    font-size: 13px;

    label {
      padding-left: 30px;
      color: #020525;
    }
  }
}

.reactTagsInput {
  :global(.react-tagsinput) {
    overflow: hidden;
    padding-top: 5px;
  }

  :global(.react-tags-wrapper) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }

  :global(.ReactTags__tag) {
    animation-name: tagAppear;
    animation-duration: 0.3s;
    animation-timing-function: cubic-bezier(0.18, 1.55, 0.525, 0.92);
    font-weight: 600;
    background-color: #dff1ef;
    border-radius: 4px;
    color: #04b9ab;
    display: inline-block;
    margin-bottom: 10px;
    margin-right: 10px;
    padding: 10px;

    button {
      transition: 0.2s all ease-in-out;
      border: none;
      color: gray;
      background: none;
      font-weight: 100;
      font-size: 18px;
      display: inline-block;
      line-height: 12px;
      width: 0px;
      overflow: hidden;
      padding: 0;
    }
    &:hover button {
      width: 15px;
      padding: 0 5px;
      margin-left: 3px;
    }
  }

  :global(.ReactTags__remove) {
    font-weight: 600;
    cursor: pointer;
  }

  :global(.ReactTags__tagInput) {
    border: none;
    margin-right: 10px;
    margin-bottom: 11px;
    input {
      &::placeholder {
        color: #04b9ab;
        text-align: center;
      }
      &:focus::placeholder {
        color: rgba(4, 185, 171, 0.49);
      }
      color: #04b9ab;
      font-weight: 600;
      background-color: #ffffff;
      border: 1px solid #20bda3;
      border-radius: 4px;
      padding: 10px;
      min-width: 145px;
      text-align: center;

      &:focus {
        outline: none;
      }
    }
  }
}

.suggestions {
  margin-top: 10px;

  &Title {
    font-size: 12px;
    color: $gray;
    font-weight: 600;
    margin: 0 0 10px;
  }

  &NewTag {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &Input {
      width: 100%;
      max-width: 263px;
      min-width: 263px;
      padding: 10px 38px 10px 12px;
      margin-right: 10px;
      border: 1px solid transparent;
      border-radius: 6px;
      color: $dark-blue;
      background-color: $superlight;
      outline: none;
      @include fast-easing(border-color);

      // fixes safari issues
      &[draggable="true"] {
        user-select: text;
      }

      &:hover {
        border-color: $line-gray;
      }

      &:focus {
        border-color: $primary;
      }
    }
  }

  &List {
    list-style: none;
    margin: 0;
    padding: 0;
    width: fit-content;

    &Item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 10px;
      background-color: #f2f6f7;
      position: relative;

      & > &Btn {
        width: 38px;
        height: 38px;
        background-color: $line-gray;

        &:not(:last-child) {
          margin-right: 10px;
        }
      }

      .suggestionsDragIcon {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 38px;
        height: 38px !important;
        top: 50%;
        left: calc(263px - 38px);
        transform: translateY(-50%);
        cursor: grab;

        &:active {
          cursor: grabbing;
        }

        :global(.svg) {
          margin-right: 0;
        }
      }

      &Btn {
        @include default-easing(background-color);

        &:hover {
          background-color: darken($line-gray, 2%);
        }

        &:focus {
          background-color: $line-gray;
          @include button-focus-shadow(darken($line-gray, 2%));
        }

        &Remove {
          &Icon {
            > path {
              stroke: $dark-blue;
              @include default-easing(stroke);
            }
          }

          &:hover,
          &:focus {
            background-color: lighten($error, 49%);

            .suggestionsListItemBtnRemoveIcon {
              > path {
                stroke: $error;
              }
            }
          }

          &:focus {
            @include button-focus-shadow(lighten($error, 49%));
          }
        }
      }
    }
  }
}
