@use "../../../../scss/variables" as *;
@use "../../../../scss/mixins" as mixins;

.wrapper {
  position: relative;
  display: block;

  &.error {
    .input {
      color: $error;
      border-color: $error;
      background-color: rgba($error, 0.05);
    }
    .label {
      color: $error;
    }
  }
}

.label {
  display: block;
  margin: 0 0 4px;
  font-size: $xxs;
  line-height: $l;
  font-weight: $semi;
  color: $gray;
}

.textarea {
  width: 100%;
  padding: $xxs $s;
  font-size: $xs;
  line-height: $l;
  color: $dark-blue;
  font-weight: $thin;
  border: 1px solid $line-gray;
  border-radius: 8px;
  outline: none;
  resize: none;
  min-height: 200px;
  @include mixins.shadow;
  @include mixins.fast-easing(border-color);

  &::placeholder {
    color: $gray;
  }

  &:hover {
    border-color: $gray;
  }

  &:focus {
    border-color: $primary;
  }
}

.maxLength {
  position: absolute;
  top: 8px;
  right: 4px;
  display: block;
  font-size: $xxs;
  color: lighten($gray, 15%);
}
