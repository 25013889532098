@import "../../scss/variables";

.wrapper {
  height: 100%;
  max-height: 100%;
  position: relative;
  overflow-y: hidden;
  padding-top: 16px;

  :global(.filterInput) {
    margin-bottom: 24px !important;
  }

  .searchContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 24px;
    grid-column-end: auto;

    .sortByContainer {
      display: flex;
      align-items: flex-end;
      margin: 17px 0 24px;

      > span {
        margin-right: 8px;
      }
    }

    .addUserContainer {
      display: flex;
      align-items: flex-end;
      margin: 0 0 24px;

      .addUserButton {
        margin-left: auto;
        margin-right: 0;
      }
    }
  }

  .resultsContainer {
    overflow-y: auto;
    height: calc(100% - 140px);
    padding-bottom: 2rem;
    .resultRow {
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid;
      border-color: #cfd0db;
      padding: 12px 0px;

      .createdAt {
        font-weight: 400;
        font-size: 14px;
        color: #8d93ab;
        flex-basis: calc(25%);
      }

      &:last-of-type {
        border-bottom: none;
      }

      &User {
        flex-basis: 25%;
        display: flex;
        flex-direction: column;

        .userName {
          font-weight: 400;
          font-size: 14px;
          color: #020525;
        }
      }

      &Role {
        flex-basis: calc(25%);
        font-weight: 400;
        font-size: 14px;
        color: #8d93ab;
        text-transform: lowercase;
      }

      &Actions {
        flex-basis: calc(15%);
        margin-left: auto;
        display: flex;

        .edit {
          background-color: #20bda3;
          color: #fff;
          border-radius: 8px;
          height: 40px;
          font-size: 14px;
        }
        button {
          background-color: #fff;
        }
      }
    }

    .loader {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .resultsHeader {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0.5rem 0;
    margin: 0;

    &Title {
      font-size: $xxs;
      color: $gray;
      font-weight: $thin;
      margin: 0;

      &:first-child {
        flex-basis: 25%;
      }
      &:nth-child(2) {
        flex-basis: calc(25%);
      }

      &:nth-child(3) {
        flex-basis: calc(25%);
      }

      &:nth-child(4) {
        flex-basis: calc(15%);
        margin-left: auto;
      }
    }
  }
}
