.dragArea {
  border: dashed grey 4px;
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;

  .dragText {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    text-align: center;
    color: grey;
    font-size: 36px;
  }
}

.dragWrapper {
  width: 100%;
  height: 100%;
  position: relative;
}
