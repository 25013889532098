@import "../../../scss/variables";

.wrapper {
  height: 100%;
  max-height: 100%;
  position: relative;
  overflow-y: hidden;
  padding-right: 0;
  padding-top: 16px;

  &Header {
    padding-right: 24px;
  }

  .button {
    margin-left: auto;
    margin-right: 0;
  }
}

.search {
  margin-bottom: 24px;
  margin-top: -50px;

  :global {
    .results {
      &.transition {
        max-height: 400px;
        overflow-y: auto;
      }
    }
  }
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.results {
  max-height: calc(100% - 37px - 41px - 54px - 32px);
  overflow-y: auto;
  padding-right: 24px;

  &NotFound {
    margin: 4rem;
  }

  &Header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0.5rem 0;
    margin: 0;

    &Title {
      font-size: $xxs;
      color: $gray;
      font-weight: $thin;
      margin: 0;

      &:first-of-type {
        flex-basis: 25%;
      }
      &:last-of-type {
        flex-basis: calc(75% - 1rem);
        margin-left: 1rem;
      }
    }
  }
}
