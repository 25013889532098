@import "../../../scss/variables";
@import "../../../scss/mixins";

.wrapper {
  width: 100%;
  position: relative;
}

.inputWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  padding: 0 0 7px 0;
  border-bottom: 1px solid $line-gray;
  cursor: pointer;

  &Tag {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    color: $superlight;
    background-color: $dark-blue;
    border-radius: 4px;
    padding: 7px;

    &Btn {
      display: none;
      align-items: center;
      justify-content: center;
      padding: 0;
      margin: 0;
      border: none;
      background-color: transparent;
      width: 13px;
      height: 13px;
      transition: background-color 0.125s ease;
      cursor: pointer;
      margin-left: 5px;
      border-radius: 5px;

      svg {
        margin: 0;

        path {
          transition: stroke 0.125s ease;
        }
      }

      &:hover {
        background-color: $error;

        svg {
          path {
            stroke: $superlight;
          }
        }
      }
    }

    &:hover {
      .inputWrapperTagBtn {
        display: flex;
      }
    }

    &:not(:last-of-type) {
      margin-right: 5px;
    }

    &:last-of-type {
      margin-right: 10px;
    }
  }

  &Input {
    display: block;
    width: 100%;
    border: none;
    background-color: transparent;
    outline: none;
  }
}

.tags {
  display: block;
  width: 100%;
  max-height: 100px;
  list-style: none;
  padding: 0;
  border-radius: 5px;
  border: 1px solid $line-gray;
  margin: 0;
  position: absolute;
  background-color: $superlight;
  top: calc(100% - 1px);
  left: 50%;
  transform: translateX(-50%);
  z-index: 200;
  box-shadow: 0px 2px 20px -8px rgba(0, 0, 0, 0.2);
  overflow: auto;

  @include vertical-scrollbar;

  &Disabled {
    cursor: not-allowed;
    background-color: $lightgray;
  }

  &Tag {
    display: block;

    &:last-of-type {
      button {
        border-radius: 0px 0px 5px 5px;
      }
    }

    &:first-of-type {
      button {
        border-radius: 5px 5px 0px 0px;
      }
    }

    &Button {
      font-size: 13px;
      color: $dark-blue;
      padding: 10px 5px;
      border: none;
      background-color: $superlight;
      margin: 0;
      transition: background-color 0.125s ease;
      cursor: pointer;
      width: 100%;
      text-align: left;

      &:not(:last-of-type) {
        margin-bottom: 7px;
      }

      &:hover {
        background-color: $light;
      }

      &:disabled {
        pointer-events: none;
        color: $gray;
      }
    }
  }
}
