@use "../../../scss/variables" as *;

.filtersSettings {
  padding: 8px 0px;
  margin: 0px 16px;
  border-bottom: 1px solid #e0e4f6;

  h6 {
    color: $gray;
    font-weight: $semi;
    font-size: 14px;
    margin: 0;
  }

  &Wrapper {
    display: flex;
    flex-direction: column;

    .checkboxItem {
      padding: 8px 0px;

      input:checked ~ label:before {
        border-color: #5fbfa4 !important;
      }

      label {
        padding-left: 0px !important;
        font-size: 14px;
        line-height: 24px;
        color: #3d3d3d;
        font-weight: $thin;
        &:before {
          right: 0;
          left: unset !important;
          width: 20px !important;
          height: 20px !important;
        }

        &:after {
          top: 4px !important;
          right: 3px !important;
          left: unset !important;
          background-color: #5fbfa4 !important;
          width: 14px !important;
          height: 14px !important;
          transform: unset !important;
        }
      }
    }

    .datePickerItem {
      padding: 8px 0px;

      .checkboxItem {
        width: 100%;
        padding: 0;
      }

      .label {
        font-size: 14px;
        line-height: 24px;
        color: #3d3d3d;
        font-weight: $thin;
      }

      .wrapper {
        display: flex;
        margin-top: 8px;

        .from {
          grid-column: 1 / 2;
          width: 100px;
          border: 1px solid #e5edf0 !important;
          border-radius: 6px;
          outline: 0;
          padding-left: 8px;
        }

        .to {
          grid-column: 2 / 4;
          width: 100px;
          border: 1px solid #e5edf0 !important;
          border-radius: 6px;
          outline: 0;
          padding-left: 8px;
        }
      }
    }
  }
}
