@import "../../../scss/mixins";
@import "../../../scss/variables";

.modal {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;

  &Overlay {
    z-index: 1;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(#000000, 0.5);
  }
}

.error {
  font-size: 16px;
  background-color: rgba($error, 0.05);
  color: $error;
  padding: 1rem;
  border-radius: 1rem;
  margin-top: 20px;
}

.content {
  width: 100%;
  max-width: #{"min(1150px, 90%)"};
  z-index: 2;
  padding: 1rem;
  background-color: #ffffff;
  border-radius: 0.5rem;
  max-height: 95vh;
  overflow: hidden;

  table {
    margin-bottom: 10px;
    margin-top: 10px;

    tr {
      background-color: #e2e2e2;
    }

    td {
      padding: 3px 6px;
    }
  }

  &TOC {
    max-width: #{"min(1450px, 90%)"};
  }

  &Breadcrumbs {
    padding: 0 !important;
  }
}

.document {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 300;
  background: #fff;
  margin: 0 auto;
  height: 99%;
  min-width: 800px;

  @media only screen and (min-width: 768px) {
    width: 88%;
  }

  @media only screen and (min-width: 992px) {
    width: 900px;
  }
  @media only screen and (min-width: 1100px) {
    width: 1050px;
  }

  @media only screen and (min-width: 1250px) {
    width: 1150px;
  }

  :global(.ui.breadcrumb) {
    padding: 5px 1rem 20px !important;
    margin: 0 !important;
  }

  .previewHeader {
    padding: 0;
    background-color: #ffffff;

    .documentName {
      height: 40px;
      color: #020525;
      font-size: 24px;
      font-weight: 600;
      line-height: 40px;
      margin: 0.25rem 1rem 0.25rem 1rem;
    }

    .localization {
      display: flex;
      margin: 0.25rem 1rem 0.75rem 1rem;
      height: 16px;
      width: 406px;
      color: $primary-vivid;
      font-size: 14px;
      line-height: 16px;

      .link {
        padding-left: 1rem;
        color: $primary-vivid;
        cursor: pointer;
      }

      p {
        color: #9c9c9c;
      }
    }
  }

  &Content {
    margin-top: 1.5rem;

    &TOC {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
    }

    > :global(.document-header) {
      padding: 0 1rem;
      flex-basis: 100%;

      :global(.group) {
        :global(svg) {
          cursor: pointer;

          path {
            fill: $primary;
          }
        }

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          :global(svg):last-of-type {
            margin-right: 0;
          }
        }
      }
    }

    &Html {
      // (modalHeigh - document header height - paddings and margins - breadcrumbs - modal header)
      max-height: calc(95vh - 50px - 6.1rem - 28px - 17px);
      overflow-y: auto;
    }

    &TOC {
      .documentContentHtml {
        flex-basis: calc(100% - 300px - 2rem);
      }

      .tableOfContents {
        flex-basis: 300px;
        // (modalHeigh - document header height - paddings and margins - breadcrumbs - modal header)
        max-height: calc(95vh - 50px - 6.1rem - 28px - 17px);
        overflow-y: auto;
        overflow-x: hidden;
        align-self: stretch;
      }
    }
  }
}

.header {
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;

  &Close {
    width: auto;
    height: auto;
    padding: 4px;
    background-color: #fff;

    > :global(svg) {
      size: unset;
      width: 20px;
      height: 20px;
    }

    &:hover {
      background-color: #f3f6f7;
    }

    &:focus {
      @include button-focus-shadow($lightgray);
    }

    &:active {
      background-color: $lightgray;
    }
  }

  &Title {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &Text {
      color: $gray;
      font-size: 12px;
      margin-left: 10px;
    }
  }
}

.contentHighlight {
  animation-delay: 0.5s;
  animation-name: contentFade;
  animation-duration: 2s;
}

@keyframes contentFade {
  0% {
  }
  20% {
    background-color: #f4ffa2;
  }
  80% {
  }
}

.titleHighlight {
  animation-delay: 0.5s;
  animation-name: titleFade;
  animation-duration: 3s;
}

@keyframes titleFade {
  0% {
  }
  20% {
    background-color: #b8e2f3;
  }
  80% {
  }
}
