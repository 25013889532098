@use "./scss/variables" as variables;
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");

html {
  font-size: variables.$s;
}

body {
  position: relative;
  padding: 0;
  box-sizing: border-box;
}

@media only screen and (max-width: 991px) {
  body.guest {
    padding: 0;
  }
}

body.guest {
  background: #f3f7fe;
}

body.logged {
  background-color: #f7f9fc;
}

* {
  font-family: "Poppins", "Helvetica Neue", Helvetica, Arial, "Lucida Grande",
    sans-serif !important;
}

body i.icon,
body .ui.sortable.table thead th:after {
  font-family: "Icons" !important;
}
body .mce-ico {
  font-family: "tinymce", Arial !important;
}

/* fixes semantic ui modals not centered vertically */
div.ui.modal {
  transform: translateY(-50%);
}

/* font weight bold -> semibold */
strong,
h5,
.ui.button,
.ui.buttons .or:before,
.ui.divider,
.ui.header,
.ui.sub.header,
.ui.label,
.ui.label > .detail,
.ui.list > .item .header,
.ui.steps .step .title,
.ui.ordered.steps .step:before,
.ui.breadcrumb .active.section,
.ui.form .field > label,
.ui.form .grouped.fields > label,
.ui.form .inline.field > p,
.ui.vertical.menu .header.item,
.ui.vertical.menu .item > .header:not(.ui),
.ui.menu .ui.dropdown .menu > .active.item,
.ui.vertical.menu .item .menu .active.item,
.ui.tabular.menu .active.item,
.ui.secondary.pointing.menu .active.item,
.ui.text.menu .header.item,
.ui.message .header,
.ui.table thead th,
.ui.category.search > .results .category > .name,
.ui.definition.table tr td.definition,
.ui.test.ad:after,
.ui.card > .content > .header:not(.ui),
.ui.comments .comment .author,
.ui.feed > .event > .content .summary,
.ui.feed > .event > .content .user,
.ui.items > .item > .content > .header,
.ui.statistic > .label,
.ui.statistic > .text.value,
.ui.styled.accordion .accordion .title,
.ui.dropdown .menu > .header,
.ui.inline.dropdown > .text,
.ui.dropdown .menu .active.item,
.ui.modal > .header:not(.ui),
.ui.popup > .header,
.ui.progress .bar > .progress,
.ui.progress > .label,
.ui.search > .results .result .title,
.ui.search > .results > .message .header,
.ui.search > .results > .action {
  font-weight: 600;
}

/* podmiana koloru czarnego z SUI na #020525 */
body,
.ui.header,
.ui.input,
.ui.input > input,
.ui.input.down input,
.ui.basic.label,
.ui.list > .item .header,
.ui.list > a.item:hover .icon,
.ui.bulleted.list > a.item:before,
.ui.horizontal.bulleted.list > .item::before,
.ui.ordered.list > .item:before,
.ui.inverted.dimmer .ui.loader,
.ui.inverted.segment .segment,
.ui.steps .step,
.ui.steps .step .description,
.ui.steps a.active.step:hover,
.ui.form .field > label,
.ui.form input[type="url"],
.ui.form textarea,
.ui.form select,
.ui.inverted.form input[type="url"],
.ui.form .grouped.fields > label,
.ui.form .inline.field > p,
.ui.menu .item.active,
.ui.menu .ui.dropdown .menu > .item,
.ui.tabular.menu .item.active,
.ui.secondary.pointing.menu a.item:hover,
.ui.message,
.ui.table,
.ui.table thead th,
.ui.table tfoot th,
.ui.table td.active,
.ui.selectable.table tr:hover td.active,
.ui.sortable.table thead th,
.ui.card .meta > a:not(.ui):hover,
.ui.comments .comment .author,
.ui.comments .comment .text,
.ui.feed > .event > .content .summary,
.ui.feed > .event > .content .extra,
.ui.items > .item > .content > .description,
.ui.items > .item .meta > a:not(.ui):hover,
.ui.statistic > .label,
.ui.accordion .title:not(.ui),
.ui.styled.accordion .accordion .active.title,
.ui.styled.accordion .accordion .active.title,
.ui.checkbox label:after,
.ui.checkbox + label,
.ui.radio.checkbox label:after,
.ui.toggle.checkbox label,
.ui.dropdown .menu > .item,
.ui.selection.dropdown,
.ui.modal > .close,
.ui.inverted.dimmer > .basic.modal,
.ui.fullscreen.modal > .close,
.ui.nag,
.ui.popup,
[data-tooltip]:after,
.ui.progress > .label,
.ui.indicating.progress[data-percent^="2"] .label,
.ui.indicating.progress[data-percent^="3"] .label,
.ui.indicating.progress[data-percent^="5"] .label,
.ui.indicating.progress[data-percent^="6"] .label,
.ui.indicating.progress[data-percent^="8"] .label,
.ui.indicating.progress[data-percent^="100"] .label,
.ui.indicating.progress[data-percent="9"] .label,
.ui.rating .icon.selected.active,
.ui.search > .prompt,
.ui.search > .results .result,
.ui.search > .results > .message .header,
.ui.search > .results > .message .description,
.ui.search > .results > .action,
.ui.category.search > .results .category.active > .name,
.ui.cube.shape .side {
  color: #020525 !important;
}

/* kreski/oddzielenia/linie na #CFD0DB */
body .ui.celled td,
body .ui.basic.buttons,
body .ui.input > input,
body .ui.basic.label,
body .ui.segment,
body .ui.basic.table.segment,
body .ui.piled.segments:after,
body .ui.piled.segments:before,
body .ui.piled.segment:after,
body .ui.piled.segment:before,
body .ui.segments,
body .ui.horizontal.segments,
body .ui.steps,
body .ui.form input:not([type]),
body .ui.form input[type="date"],
body .ui.form input[type="datetime-local"],
body .ui.form input[type="email"],
body .ui.form input[type="number"],
body .ui.form input[type="password"],
body .ui.form input[type="search"],
body .ui.form input[type="tel"],
body .ui.form input[type="time"],
body .ui.form input[type="text"],
body .ui.form input[type="file"],
body .ui.form input[type="url"],
body .ui.form textarea,
body .ui.form select,
body .ui.menu,
body .ui.table,
body .ui.basic.table,
body .ui.dropdown .menu,
body .ui.selection.dropdown,
body select.ui.dropdown,
body .ui.search > .prompt {
  border-color: #cfd0db;
}

/* Fill whole viewport */
body,
div#root,
div#root > div,
div#root > div > div.grid,
div#root > div > div.grid > div.row,
div#root > div > div.grid > div.row > div.column {
  height: 100%;
}

.ui.mini.basic.modal.active {
  transform: translateY(-50%);
}

#portal_container.display {
  display: flex;
  align-items: center;
  overflow: hidden;
  z-index: 2000;
  position: fixed;
  width: 100%;
  height: 100%;
}

.portalDimmer {
  background: #000;
  opacity: 0.5;
  position: fixed; /* important to use fixed, not absolute */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.appContainer {
  width: 100%;
  height: 100%;
  background-color: #f7f9fc;
  padding: 60px 40px 32px 80px;
}

@keyframes reduceAppContainer {
  from {
    padding-left: variables.$menuLeftPaddingExpanded;
  }
  to {
    padding-left: variables.$menuLeftPaddingReduced;
  }
}

@keyframes expandAppContainer {
  from {
    padding-left: variables.$menuLeftPaddingReduced;
  }
  to {
    padding-left: variables.$menuLeftPaddingExpanded;
  }
}

.appContainerReduced {
  animation: reduceAppContainer variables.$menuAnimationTime;
  padding-left: variables.$menuLeftPaddingReduced;
}

.appContainerExpanded {
  animation: expandAppContainer variables.$menuAnimationTime;
  padding-left: variables.$menuLeftPaddingExpanded;
}

.train-main-container {
  height: 100%;
  &-expanded {
    height: auto !important;
    min-height: 100% !important;
    padding: 0 0 94px 0;
  }
}

.globalTooltip {
  box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.37);
}

b,
strong {
  &[data-matching="true"] {
    color: variables.$matching-word !important;
  }
}

span.queryMatched {
  background-color: variables.$matching-highlight !important;
}

span.currentOccurrence {
  background-color: yellow !important;
}

body.article-app-container {
  .appContainer {
    padding-bottom: 64px;
  }
}
