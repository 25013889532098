@use "../../../../scss/mixins" as *;

.wrapper {
  display: flex;
  align-items: center;
  padding: 0px 28px;
  grid-column: 1 / 2;
  max-height: 90px;

  .header_and_actions_buttons_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px;
    margin-left: auto;
  }

  .article_title_input_wrapper {
    display: flex;
    align-items: center;
    margin: 16px 0px;
    overflow-y: hidden;

    .inputWrapper {
      &.newArticleMode {
        margin: 8px 0px;
      }

      .label {
        .dummy {
          position: absolute;
          white-space: pre;
          min-width: 250px;
          line-height: 24px;
          margin: 4px 0px 0px 0px;
          overflow: hidden;
          padding: 16px;
          pointer-events: none;
          text-overflow: ellipsis;
          top: 0;
          z-index: 1;
          color: $dark-blue;
          font-size: 18px;
          font-weight: $normal;

          &.hide {
            visibility: hidden;
          }

          &.maxWidth {
            max-width: 560px;
          }
        }
      }

      .input {
        font-weight: $normal;
        font-size: 18px;
        text-overflow: ellipsis;
        padding: 0px;
        min-width: 250px;
        color: $superlight;
        border: none;
        box-shadow: none;

        &.empty {
          padding: 16px;
        }

        &:not(:focus) {
          color: $superlight;
          max-width: 560px;

          &.inactive {
            box-shadow: unset;
            border: 0;
            padding: 16px;

            ~ span {
              display: none;
            }
          }
        }

        &:focus {
          padding: 16px 4px 16px 16px;
          color: $dark-blue;
        }
      }
    }

    .autosave {
      display: flex;
      align-items: center;
      color: $text-gray-light;
      font-size: 14px;
      font-weight: $thin;
      margin-left: 18px;
      white-space: nowrap;

      .savingError {
        color: $errorBg;
        display: flex;
        align-items: center;

        svg {
          width: 14px;
          height: 14px;
          border: 1px solid $errorBg;
          border-radius: 50%;

          path {
            stroke: $errorBg;
          }
        }
      }

      svg {
        margin-right: 8px;
      }

      .saving {
        @keyframes spin {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }

        animation-name: spin;
        animation-duration: 1000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
      }
    }
  }
}
