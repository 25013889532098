.item {
  text-align: right;
  padding: 0 !important;
}

.flex {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
}

.avatar {
  width: 40px;
  height: 40px;
  flex-basis: 40px;
  flex-grow: 0;
  flex-shrink: 0;
  display: inline-block;
  svg {
    margin: 0;
  }
}

.block {
  display: inline-block;
  margin-right: 15px;
  max-width: 40%;
}

.message {
  padding: 10px 15px;
  border-radius: 15px 15px 0px 15px;
  background-color: #ececec;
  color: #525151;
  margin-top: 10px;
  font-size: 14px;
}

.header {
  color: #7e7e7e;
  font-size: 12px;
  font-weight: 500;
  text-align: right;
}

.timer {
  margin-top: 5px;
  margin-right: 55px !important;
  display: block;
}
