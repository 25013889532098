@use "../../../scss/mixins" as mixins;
@use "../../../scss/variables" as *;

.result {
  padding: 16px;
  border-radius: 6px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
  background-color: #ffff;
  margin-bottom: 16px;
  width: 100%;
  height: 100%;

  &.relatedResult {
    width: calc(100% - 11px);
  }

  &:last-of-type {
    margin-bottom: 0px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 50px;
}

.document {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;

  &Icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 34px;
    height: 34px;
    margin-right: 12px;
    flex-basis: 34px;
    border-radius: 0.5rem;
    margin-top: -4px;
    background-color: $superlight;
    cursor: pointer;
    @include mixins.fast-easing(backround-color);

    &:hover,
    &:focus {
      background-color: $light;
    }

    @include mixins.shadow;

    :global svg {
      margin: 0;
      max-height: 20px;
      max-width: 20px;
    }
  }

  &Name {
    margin: 0;
    font-size: $xs;
    line-height: $s;
    font-weight: $semi;
    color: $blue;
    text-decoration: underline;
    flex-basis: #{"min(calc(100% - 12px - 34px), 100%)"};
    display: inline-block;
    cursor: pointer;
    outline: none;
    @include mixins.fast-easing(color);
    word-break: break-word;
    margin-bottom: $xs;

    &:hover,
    &:focus {
      color: $primary;
    }

    &:focus {
      text-decoration: none;
    }
  }

  .breadcrumbs {
    flex-basis: 100%;
    margin-top: -8px;
    margin-left: 20px;
    padding-left: 0;
    padding-top: 0;
  }

  .breadcrumb {
    &:first-child > :global(div) {
      border-radius: 5px;
      background-color: $superlight;
      @include mixins.shadow;
    }
  }
}

.modification {
  text-align: right;
  color: $gray;
  font-size: $xxs;
  line-height: $s;
  font-weight: $thin;
  justify-self: flex-end;
  min-width: 150px;
  margin-left: auto;

  :global b {
    font-weight: $normal;
  }
}
