@use "../../../../../scss/variables" as *;
@use "../../../../../scss/mixins" as mixins;

.handle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: unset;
  background-color: transparent;
  border: none;
  z-index: 2;
  border: 1px solid transparent;
  @include mixins.combined-transitions(
    background-color 0.2s ease,
    border-color 0.2s ease,
    box-shadow 0.2s ease
  );

  &Right {
    right: -8px;
  }

  &Left {
    left: -8px;
  }

  &::after {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    @include mixins.default-easing(border-color);
    background-color: $superlight;
    border: 1px solid $line-gray;
  }

  &:hover {
    box-shadow: mixins.$defaultShadow;
    border-color: $gray;
    background-color: $superlight;

    &::after {
      border-color: transparent;
    }
  }
}

.wrapper {
  padding: 8px;
  border-radius: 8px;
  background-color: $superlight;
  display: flex;
  flex-direction: column;
  position: relative;
  border: 1px solid $gray;
  @include mixins.default-easing(box-shadow);

  &:hover {
    @include mixins.shadow(rgba(0, 0, 0, 0.2), 0px, 0px, 4px, 0px);

    .actions {
      transform: translateX(54px) translateY(-50%);
      opacity: 1;
    }
  }
}

.actions {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 25px;
  position: absolute;
  top: 50%;
  right: 0;
  opacity: 0;
  z-index: -1;
  transform: translateX(-20px) translateY(-50%);
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;

  .actionButton {
    &:not(:first-child) {
      margin-top: 5px;
    }

    padding: 8px;

    svg {
      size: unset;
      width: 12px;
      height: 12px;
    }
  }
}
