@import "../../../scss/mixins";

.alert-modal {
  &-enter {
    opacity: 0;
    transform: translateY(200%);
    @include default-easing(opacity, transform);

    &-active {
      opacity: 1;
      transform: translateY(0);
      @include default-easing(opacity, transform);
    }
  }

  &-exit {
    opacity: 1;
    transform: translateY(0);
    @include default-easing(opacity, transform);

    &-active {
      opacity: 0.25;
      transform: translateY(300%);
      @include default-easing(opacity, transform);
    }
  }
}
