@import "../../../scss/variables";
@import "../../../scss/keyframes";

@keyframes expandBar {
  from {
    max-width: $menuReducedWidth;
  }
  to {
    max-width: $menuExpandedWidth;
  }
}

@keyframes reduceBar {
  from {
    max-width: $menuExpandedWidth;
  }
  to {
    max-width: $menuReducedWidth;
  }
}

$logoMarginReduced: -16px;
$logoMarginExpanded: 0;

@keyframes logoMoveLeft {
  from {
    margin-left: $logoMarginExpanded;
  }
  to {
    margin-left: $logoMarginReduced;
  }
}

@keyframes logoMoveRight {
  from {
    margin-left: $logoMarginReduced;
  }
  to {
    margin-left: $logoMarginExpanded;
  }
}

.sidebar {
  width: 100%;
  max-width: $menuReducedWidth;
  height: 100%;
  background-color: $dark-blue;
  position: fixed;
  top: 0;
  left: 0;
  padding: 0 !important;
  z-index: 999;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &_logo {
    margin: 20px auto 65px 24px;
    line-height: 1;

    svg {
      size: unset;
      margin: 0;
      max-height: 19px;

      &.reduced {
        animation: logoMoveLeft $menuAnimationTime;
        margin-left: $logoMarginReduced;

        :global path.reduced-hidden {
          animation: fadeOut $menuAnimationTime;
          opacity: 0;
        }
      }

      &.expanded {
        animation: logoMoveRight $menuAnimationTime;
        margin-left: $logoMarginExpanded;

        :global path.reduced-hidden {
          animation: fadeIn $menuAnimationTime;
          opacity: 1;
        }
      }
    }
  }
}

.sidebarExpanded {
  animation: expandBar $menuAnimationTime;
  max-width: $menuExpandedWidth;
}

.sidebarReduced {
  animation: reduceBar $menuAnimationTime;
  max-width: $menuReducedWidth;

  :global(.sidebarMenu) {
    overflow-x: hidden;
  }
}
