.button {
  background-color: rgba(0, 185, 171, 0.1) !important;
  color: rgba(0, 185, 171, 1) !important;
  font-size: 14px !important;
  border-radius: 6px !important;

  &Featured {
    background-color: rgba(0, 185, 171, 1) !important;
    color: #fff !important;
  }
}
