$primary: #20bda3;
$primary-shadowed: #00796b;
$primary-darken: #01796b;
$primary-vivid: #00b0a3;
$primary-bg-light: #f2fffd;
$dark-blue: #020525;
$text-gray: #3d3d3d;
$text-gray-light: #9c9c9c;
$gray: #8d93ab;
$lightgray: #eeeff5;
$line-gray: #dcdee9;
$superlight: #fff;
$light: #f3f6f7;
$lighter: #f9fafb;
$error: #aa1a1a;
$errorBg: #d42e2e;
$warning: #af5d07;
$blue: #2148d1;
$light-blue: #e5f6fd;
$text-blue: #014361;
$matching-word: #5fbfa4;
$matching-highlight: #dcf0ef;
$navy-blue: #000080;
$light-bg: #f2f6f7;
$gray-placeholder: #c7c7c7;
$light-border: #e5edf0;

// font sizes
$xxs: 12px;
$xs: 14px;
$s: 16px;
$m: 20px;
$l: 24px;

// font-weights
$thin: 400;
$normal: 500;
$semi: 600;
$bold: 700;

$default-easing-timing: 0.2s ease;
$fast-easing-timing: 0.2s ease;

$menuExpandedWidth: 240px;
$menuReducedWidth: 70px;
$menuLeftPaddingReduced: 102px;
$menuLeftPaddingExpanded: 272px;
$menuAnimationTime: 0.5s;
