@import "../../../scss/mixins";
@import "../../../scss/variables";

.wrapper {
  width: 100%;
  border-radius: 16px;
  @include main-box-shadow;
  padding: 0 24px 0;
  background-color: $superlight;

  &Header {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    min-height: 54px;

    &Between {
      justify-content: space-between;
    }
  }
}

.title {
  font-size: 16px;
  font-weight: 400;
  padding: 0;
  color: $dark-blue;
  text-align: left;
  margin: 0;
}
