@use "../../../scss/variables" as *;

.wrapper {
  padding: 8px 0px;
  margin: 0px 16px;
  border-bottom: 1px solid #e0e4f6;

  h6 {
    color: $gray;
    font-weight: $semi;
    font-size: 14px;
    margin: 0;
  }

  .optionsWrapper {
    padding: 8px 0px;

    .loading {
      min-height: 200px;
      display: flex;
    }

    :global(.ui) {
      &:global(.checkbox) {
        &:global(.checked) {
          input:checked ~ label:before {
            background-color: rgba(0, 0, 0, -0.98) !important;
            border: 1px solid #5fbfa4 !important;
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 4%);
          }
        }
        input:focus ~ label:before {
          background: rgba(0, 0, 0, -0.98) !important;
          box-shadow: 0px 4px 20px rgba(0, 0, 0, 5%);
          border: none;
        }
      }
    }

    :global(.checkbox) {
      z-index: 0;
      label {
        padding: 8px 16px;
        margin: 4px;
        display: flex;
        font-size: 14px;

        svg {
          margin-right: 12px;
          width: 12px;
          height: 16px;
        }

        &:before {
          width: 100% !important;
          padding: 0px !important;
          height: 100%;
          border-radius: 8px;
          background: rgba(0, 0, 0, -0.98);
          box-shadow: 0px 4px 20px rgba(0, 0, 0, 5%);
        }

        &:after {
          display: none;
        }
      }
    }
  }
}
