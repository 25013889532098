@import "../../scss/variables";
@import "../../scss/mixins";

:global(.modals.dimmer) {
  .modal {
    &:global(.ui.modal) {
      width: 100%;
      max-width: 400px;
      margin: 0 0 0 -200px !important;
      position: absolute !important;

      &:global(.scrolling) {
        max-height: 90vh;
        overflow: auto;
      }
    }
  }
}

.modal {
  &Footer {
    padding: 24px 24px 24px;

    &Button {
      width: 100%;
      font-size: 14px;

      &:first-of-type {
        margin-bottom: 12px;
      }

      &Close {
        box-shadow: inset 0px 0px 1px $superlight !important;
      }
    }
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  padding: 16px 16px 24px 24px;
}

.heading {
  flex-basis: calc(100% - 24px);
  margin: 0;
  font-size: $s;
  color: $dark-blue;
  font-weight: 600;
  line-height: 24px;
}

.description {
  flex-basis: 100%;
  color: $dark-blue;
  font-size: 14px;
  margin: 12px 0 24px;
}

.closeButton {
  flex-basis: 24px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: $lightgray;
  padding: 0;

  &:hover,
  &:active {
    background-color: darken($lightgray, 10%);
  }

  &:focus,
  &:active {
    @include button-focus-shadow(darken($lightgray, 10%));
  }

  &Icon {
    width: 18.45px;
    height: 18.45px;
  }
}

.reasons {
  display: block;
  padding: 0 24px 24px;

  .reasonButton {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: $superlight;
    color: $dark-blue;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid $line-gray;
    border-radius: 5px;
    padding: 10px;

    svg {
      flex-basis: 24px;
      padding: 0;
      margin-right: 10px;
    }

    &:not(:first-of-type) {
      margin-top: 12px;
    }

    &:hover {
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
      background-color: $superlight;
    }

    &Picked {
      border-color: $primary;
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
    }
  }
}

.otherReason {
  width: 100%;
  border: none;
  border-bottom: 1px solid $line-gray;
  resize: none;
  min-height: 80px;
  outline: none;
  height: auto !important;
  padding: 12px;
  font-size: 14px;
  color: $dark-blue;
  margin-top: 12px !important;

  &:focus,
  &:hover {
    border-bottom-color: $primary;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  }

  &:not(:empty) {
    border-bottom-color: $primary;
  }
}

.tags {
  padding: 0 24px;
}

.tagsHeading {
  font-size: 12px;
  font-weight: 500;
  color: $gray;
  margin: 0 0 15px 0;
}
