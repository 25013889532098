@import "../../../scss/mixins";
@import "../../../scss/variables";

.wrapper {
  margin-left: 32px;
  max-width: 400px;
  height: 100%;
  max-height: 100%;
  overflow-y: hidden;
  padding-bottom: 16px;
  padding-right: 0;
}

.results {
  height: 100%;
  max-height: calc(100% - 62px);
  overflow-y: auto;
}

.closeButton {
  background-color: $superlight;
  margin-right: 11px;
  @include default-easing(background-color);

  &:hover {
    background-color: darken($superlight, 5%);
  }

  &:focus {
    @include button-focus-shadow(darken($superlight, 5%));
  }
}
